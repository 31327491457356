<template>
  <transition
    name="dialog-base"
    appear
  >
    <div
      class="dialog-base"
      :class="unobtrusiveMode ? 'dialog-base--unobtrusive-mode' : ''"
      @click="closeDialog"
    >
      <div class="dialog-base__wrapper">
        <div
          ref="dialogContainer"
          :style="`max-width: ${maxWidth}px; ${cssPosition}`"
          role="dialog"
          :aria-label="dialogContainerAriaLabel"
          class="dialog-base__container"
          data-test-id="dialog-base-container"
          tabindex="0"
          @click.stop
        >
          <icon-component
            v-show="backButton"
            container="button"
            name="caret"
            class="dialog-base__icon-button dialog-base__icon-button--back"
            data-test-id="dialog-base-back-button"
            type="button"
            title="back"
            aria-label="back"
            @click="goBack"
          />
          <site-logo v-if="showLogo" />
          <icon-component
            container="button"
            name="x"
            class="dialog-base__icon-button dialog-base__icon-button--close"
            data-test-id="dialog-base-close-button"
            type="button"
            title="close"
            aria-label="close"
            @click="closeDialog"
          />
          <icon-component
            v-if="shouldIncludeIcon"
            :name="iconNameBasedOnDialogType"
            class="dialog-base__icon"
          />
          <h2
            v-if="$slots.heading"
            id="dialog-heading"
            class="dialog-base__heading"
          >
            <slot name="heading" />
          </h2>
          <p
            v-if="$slots.copy"
            id="dialog-copy"
            class="dialog-base__copy"
            data-test-id="dialog-base-copy"
          >
            <slot name="copy" />
          </p>
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SiteLogo from '@/components/Global/SiteLogo.vue';
import { isEmptyObject } from '@/utils/utils';

export default {
  name: 'DialogBase',
  components: {
    SiteLogo,
  },
  props: {
    maxWidth: {
      type: Number,
      default: 500,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
    // This object must have keys top, left, bottom, right
    // Values must be integers, and will be converted to px values
    // See UserByline.vue for an example of how this gets set through vuex
    position: {
      type: Object,
      default: () => {},
    },
    dialogType: {
      type: String,
      default: '',
    },
    ariaLabelText: {
      type: String,
      default: '',
    },
    // unobtrusiveMode places the dialog near the bottom of the user's
    // screen with no overlay on the rest of the page.
    unobtrusiveMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Default position is horizontally centered
      cssPosition: 'top: 80px; left: 50%; transform: translateX(-50%);',
      positionOffset: 40,
    };
  },
  computed: {
    shouldIncludeIcon() {
      return this.dialogType.includes('accountConfirmation') || this.dialogType.includes('verifyViaEmail');
    },
    iconNameBasedOnDialogType() {
      const name = {
        accountConfirmation: 'check',
        accountConfirmationWithNoCTA: 'check',
        verifyViaEmail: 'flying-envelope',
      }[this.dialogType] || '';

      return name;
    },
    dialogContainerAriaLabel() {
      if (this.ariaLabelText) {
        return this.ariaLabelText;
      }
      if (this.$slots.heading) {
        return this.$slots.heading[0].text;
      }
      return 'Dialog';
    },
    dialogSize() {
      return this.$refs.dialogContainer.getBoundingClientRect();
    },
    isOffscreenY() {
      return this.dialogSize.height + this.position.bottom > window.innerHeight;
    },
    isOffscreenX() {
      return this.dialogSize.width + this.position.right > window.innerWidth;
    },
    backButton() {
      return {
        loginWithEmail: { action: 'openLoginDialog' },
        registerWithEmail: { action: 'openRegisterDialog' },
        requestVerificationText: { action: 'openVerificationPrompt' },
        requestPasswordReset: { action: 'openLoginDialog' },
      }[this.dialogType] || '';
    },
  },
  mounted() {
    this.$refs.dialogContainer.focus();

    if (this.unobtrusiveMode) {
      this.cssPosition = 'top: auto; left: 50%; bottom: 16px; transform: translateX(-50%);';
    }

    if (!isEmptyObject(this.position)) {
      this.setCssPosition();
    }
    document.addEventListener('keyup', (event) => {
      if (event.keyCode === 27) {
        this.closeDialog();
      }
    });

    this.$snowplow.enableDialogTracking({
      data: {
        dialog_type: this.dialogType,
        dialog_text: `${this.$slots.heading ? this.$slots.heading[0].text : ''}`,
        dialog_class_list: ['dialog-base__container'],
      },
    });
  },
  methods: {
    resetFocus() {
      const rootElement = document.querySelector('#app');
      if (!rootElement) { return; }
      rootElement.setAttribute('tabindex', -1);
      rootElement.focus();
      rootElement.addEventListener('blur', (e) => {
        e.target.removeAttribute('tabindex');
      }, { once: true });
    },
    closeDialog() {
      this.$emit('close');
      this.$store.dispatch('resetSourceContext');

      this.resetFocus();
    },
    goBack() {
      this.$store.dispatch(this.backButton.action);
    },
    setCssPosition() {
      if (this.isOffscreenY && this.isOffscreenX) {
        this.cssPosition = `
          bottom: ${this.positionOffset}px;
          left: ${this.positionOffset}px`;
        return;
      }
      if (this.isOffscreenY) {
        this.cssPosition = `
          bottom: ${this.positionOffset}px;
          left: ${this.position.right}px`;
        return;
      }
      if (this.isOffscreenX) {
        this.cssPosition = `
          top: ${this.position.top}px;
          right: ${this.positionOffset}px`;
        return;
      }

      this.cssPosition = `top: ${this.position.top}px; left: ${this.position.right + this.positionOffset}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_dialog-base';
</style>

<docs>
Renders an almost-style-less, centered container with a shadow and an overlay
Emits a `close` event on click outside the main container

**Note:** The `close` event is emitted by the dialog base and must be handled by the dialog itself.
</docs>
