<template>
  <loading-button
    class="resend-verification-email text-button text-button--link-style"
    :loading="tryingToSubmit"
    @click="resendEmail"
  >
    <slot>verify via email</slot>
  </loading-button>
</template>

<script>
import LoadingButton from '@/components/Global/LoadingButton.vue';
import { RESEND_EMAIL_VERIFICATION } from '@/graphql/mutations/user-mutations';
import { mapGetters } from 'vuex';

export default {
  name: 'ResendVerificationEmail',
  components: { LoadingButton },
  data() {
    return {
      tryingToSubmit: false,
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userIsUnverified',
    ]),
  },
  methods: {
    resendEmail() {
      if (!this.userIsLoggedIn || !this.userIsUnverified) {
        this.$store.dispatch('addToastNotification', { toastType: 'error', description: 'You must be logged in and unverified to resend a verification email.' });
        this.$logger.error('[Invalid Resend Email Verification Attempt: ResendVerificationEmail]');
        return;
      }

      this.tryingToSubmit = true;
      this.$apollo.mutate({
        mutation: RESEND_EMAIL_VERIFICATION,
      }).then(() => {
        this.$store.dispatch('setAuthStep', { authStep: 'verifyViaEmail' });

        this.$store.dispatch('addToastNotification', {
          toastType: 'success',
          description: 'Email resent.',
        });
      }).catch((error) => {
        this.$logger.error(`[Resend Email Verification] ${error}`);
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: `${error}`.replace('Error: GraphQL error: ', ''),
        });
      }).finally(() => {
        this.tryingToSubmit = false;
      });
    },
  },
};
</script>
