<template>
  <div id="navigation-drawer" class="navigation-drawer">
    <div
      class="navigation-drawer__overlay"
      @click="close"
    />
    <transition
      name="navigation-drawer"
      appear
    >
      <div
        ref="drawer"
        tabindex="0"
        class="navigation-drawer__drawer"
      >
        <site-search
          @blur="handleSearchBlur"
        />
        <nav v-if="menu" class="navigation-drawer__nav">
          <ul class="navigation-drawer__list">
            <template v-if="isParentSite">
              <nav-item-sub-community
                v-for="community in sortedChildCommunities"
                :key="community.id"
                :community="community"
              />
            </template>
            <nav-item
              v-for="item in menu.items"
              :key="item.menuId"
              :item="item"
              @navChange="updateNavState"
            />
          </ul>
        </nav>
      </div>
    </transition>
  </div>
</template>

<script>
import NavItemSubCommunity from '@/components/NavigationDrawer/NavItemSubCommunity.vue';
import NavItem from '@/components/NavigationDrawer/NavItem.vue';
import SiteSearch from '@/components/Search/SiteSearch.vue';
import { GET_ALL_MENU_ITEMS } from '@/graphql/queries/navigation-queries';
import childSitesMixin from '@/mixins/child-sites-mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'NavigationDrawer',
  components: {
    NavItemSubCommunity,
    NavItem,
    SiteSearch,
  },
  mixins: [childSitesMixin],
  props: {
    focusRef: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      menu: {},
    };
  },
  computed: {
    ...mapGetters(['navOpen', 'isParentSite']),
  },
  watch: {
    navOpen(isOpen) {
      if (isOpen && !this.focusRef) {
        this.$refs.drawer.focus();
      }
      if (!isOpen) {
        this.$store.dispatch('setFocusSearch', false);
      }
    },
  },
  mounted() {
    document.addEventListener('keyup', this.escapeKeyEventListener);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.escapeKeyEventListener);
  },
  methods: {
    close() {
      this.$store.dispatch('setNavOpen', false);
    },
    updateNavState(selectedItems) {
      this.$store.dispatch('setExpandedNavItems', selectedItems);
    },
    escapeKeyEventListener(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },
    handleSearchBlur() {
      this.$store.dispatch('setFocusSearch', false);
    },
  },
  apollo: {
    menu: {
      query: GET_ALL_MENU_ITEMS,
      result({ data }) {
        if (data) {
          try {
            [this.menu] = data.menu;
          } catch (error) {
            throw new Error('Error in NavigationDrawer query, unable to destructure menu');
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_navigation-drawer';
</style>
