<template>
  <form-component class="register-with-email" @submit.prevent="tryToRegister">
    <inline-validation-error
      v-if="registrationAttemptFailed"
      :validation-text="registrationAttemptFailedErrorMessage"
      :validation-button-text="registrationAttemptFailedButtonText"
      :validation-button-action="registrationAttemptFailedButtonAction"
    />
    <div
      v-if="siteIsSHN"
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.fname.$invalid }"
    >
      <validation-error
        ref="fname"
        class="validation-error--label"
        :model="fname"
        :validator="$v.fname"
      />
      <text-input
        id="fname"
        v-model="fname"
        name="fname"
        input-type="text"
        class="text-input--full-width"
        @input="setRegistrationFailedAttemptToFalse"
      >First name
      </text-input>
    </div>
    <div
      v-if="siteIsSHN"
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.lname.$invalid }"
    >
      <validation-error
        ref="lname"
        class="validation-error--label"
        :model="lname"
        :validator="$v.lname"
      />
      <text-input
        id="lname"
        v-model="lname"
        name="lname"
        input-type="text"
        class="text-input--full-width"
        @input="setRegistrationFailedAttemptToFalse"
      >Last name
      </text-input>
    </div>
    <p v-if="siteIsSHN" class="input-disclaimer disclaimer-text">
      <slot name="disclaimer">
        Your name will be publicly visible.  You can change this setting in your profile.
      </slot>
    </p>
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.email.$invalid }"
    >
      <validation-error
        ref="email"
        class="validation-error--label"
        :model="email"
        :validator="$v.email"
      />
      <text-input
        id="email"
        v-model="lowerCaseEmailInput"
        name="email"
        input-type="email"
        class="text-input--full-width text-input--lowercase"
        data-test-id="register-with-email-email-input"
        autocapitalize="off"
        @input="setRegistrationFailedAttemptToFalse"
      >Email address</text-input>

    </div>
    <username-input
      v-if="!siteIsSHN"
      ref="username"
      v-model="username"
      @input="setRegistrationFailedAttemptToFalse"
    />
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.password.$invalid }"
    >
      <validation-error
        ref="password"
        class="validation-error--label"
        :model="password"
        :validator="$v.password"
      />
      <password-input
        id="password"
        v-model="password"
        name="password"
        class="password-input--full-width"
        data-test-id="register-with-email-password-input"
        show-disclaimer
        @input="setRegistrationFailedAttemptToFalse"
      />
      <password-strength-indicator v-if="showPasswordStrengthIndicator" :password="password" />
    </div>
    <recaptcha-component ref="recaptcha" @verify="onCaptchaVerified" />
    <loading-button
      class="register-with-email__button button--center"
      type="submit"
      :loading="tryingToRegister"
      button-type="registration"
    >
      {{ buttonText }}
    </loading-button>
    <progress-indicator current-step="1" />
  </form-component>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  required, requiredIf, email, helpers,
} from 'vuelidate/lib/validators';
import TextInput from '@/components/Inputs/TextInput.vue';
import ValidationError from '@/components/Global/ValidationError.vue';
import RecaptchaComponent from '@/components/Auth/RecaptchaComponent.vue';
import UsernameInput from '@/components/Auth/UsernameInput.vue';
import PasswordInput from '@/components/Auth/PasswordInput.vue';
import LoadingButton from '@/components/Global/LoadingButton.vue';
import InlineValidationError from '@/components/Auth/InlineValidationError.vue';
import PasswordStrengthIndicator from '@/components/Auth/PasswordStrengthIndicator.vue';
import ProgressIndicator from '@/components/Auth/ProgressIndicator.vue';
import dataLayerMixin from '@/mixins/data-layer-mixin';

const passwordRulesHelper = helpers.regex('passwordRulesHelper', /^(?=.*\d).{8,}$/i);
const notJustNums = helpers.regex('notJustNums', /\D/);

export default {
  name: 'RegisterWithEmail',
  components: {
    TextInput,
    ValidationError,
    RecaptchaComponent,
    UsernameInput,
    PasswordInput,
    LoadingButton,
    InlineValidationError,
    PasswordStrengthIndicator,
    ProgressIndicator,
  },
  mixins: [dataLayerMixin],
  data() {
    return {
      username: '',
      fname: '',
      lname: '',
      tryingToRegister: false,
      registrationAttemptFailed: false,
    };
  },
  validations: {
    fname: {
      required: requiredIf((v) => v.siteIsSHN),
    },
    lname: {
      required: requiredIf((v) => v.siteIsSHN),
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      passwordRulesHelper,
      notJustNums,
    },
  },
  computed: {
    ...mapGetters([
      'siteHasFeature',
      'logInEmail',
      'logInPassword',
    ]),
    email: {
      get() {
        return this.logInEmail;
      },
      set(value) {
        this.$store.commit('setEmail', value);
      },
    },
    password: {
      get() {
        return this.logInPassword;
      },
      set(value) {
        this.$store.commit('setPassword', value);
      },
    },
    formIsInvalid() {
      return this.$v.$anyError;
    },
    siteIsSHN() {
      return this.siteHasFeature('social_network');
    },
    lowerCaseEmailInput: {
      get() {
        return this.email;
      },
      set(val) {
        this.email = val.toLowerCase().trim();
      },
    },
    registrationAttemptFailedErrorMessage() {
      return 'Something went wrong.  If you already have an account,';
    },
    registrationAttemptFailedButtonText() {
      return 'log in here.';
    },
    registrationAttemptFailedButtonAction() {
      return 'openLoginDialog';
    },
    showPasswordStrengthIndicator() {
      return this.password.length && !this.$v.password.$invalid;
    },
    buttonText() {
      if (this.siteHasFeature('rare_disease') || this.siteHasFeature('social_network')) {
        return 'Continue';
      }
      return 'Sign up';
    },
  },
  methods: {
    registrationSuccess(response) {
      this.setDataLayer();
      this.pushDataLayer();
      this.setTrackingInAC(this.email);

      this.$store.dispatch('clearLogInForm');

      if (response.user && response.user.isVerified) {
        this.$store.dispatch('closeAuthDialog');
        this.$store.dispatch('addToastNotification', {
          toastType: 'success',
          description: `Welcome back ${this.$store.state.currentUser.user.username}!`,
        });
      } else if (this.siteHasFeature('rare_disease')) {
        this.$store.dispatch('openAuthDialog', { authStep: 'requestDisorderDemographicInfo' });
      } else if (this.siteHasFeature('social_network')) {
        this.$store.dispatch('openAuthDialog', { authStep: 'setUsername' });
      } else {
        this.$store.dispatch('openAuthDialog', { authStep: 'verificationOptions', dialogText: 'Please verify your account.', emailAlreadySent: true });
      }
    },
    registrationFailed() {
      this.registrationAttemptFailed = true;
    },
    setRegistrationFailedAttemptToFalse() {
      this.registrationAttemptFailed = false;
    },
    onCaptchaVerified(recaptchaToken) {
      return this.$auth.register({
        fname: this.fname,
        lname: this.lname,
        username: this.username,
        email: this.email,
        password: this.password,
        recaptcha_token: recaptchaToken,
      })
        .then((response) => {
          if (response.username_exists) {
            this.$store.dispatch('addToastNotification', {
              toastType: 'error',
              description: 'This username already exists. Please try another username.',
            });
            return;
          }
          if (response.status === 'success') {
            this.registrationSuccess(response);
            return;
          }
          this.registrationFailed();
        })
        .catch(() => {
          this.registrationFailed();
        }).finally(() => {
          this.tryingToRegister = false;
        });
    },
    tryToRegister() {
      this.tryingToRegister = true;

      this.$v.$touch();

      if (this.siteIsSHN) {
        this.$refs.fname.checkErrors();
        this.$refs.lname.checkErrors();
      }

      if (!this.siteIsSHN) {
        this.$refs.username.checkErrors();
      }

      this.$refs.email.checkErrors();
      this.$refs.password.checkErrors();

      if (this.$v.$invalid) {
        this.tryingToRegister = false;
        return;
      }

      this.$refs.recaptcha.execute();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_register-with-email';
  @import '@/stylesheets/components/_input-disclaimer';
</style>
