<template>
  <div class="set-social-sites">
    <user-social-form
      ref="form"
      save-button-text="Continue"
      @success="handleSuccess"
    >
      <template v-slot:disclaimer>This information will be publicly visible.</template>
    </user-social-form>
    <progress-indicator current-step="4" />
  </div>
</template>

<script>
import ProgressIndicator from '@/components/Auth/ProgressIndicator.vue';
import UserSocialForm from '@/components/User/UserSocialForm.vue';
import { isTablet } from '@/utils/viewport-width-helpers';
import { mapGetters } from 'vuex';

export default {
  name: 'SetsocialSites',
  components: {
    ProgressIndicator,
    UserSocialForm,
  },
  computed: {
    ...mapGetters([
      'userIsUnverified',
      'authDialogRedirect',
    ]),
  },
  mounted() {
    if (isTablet()) this.$refs.form.addInput();
  },
  methods: {
    handleSuccess() {
      if (this.userIsUnverified) {
        this.$store.dispatch('setAuthStep', { authStep: 'verificationOptions' });
        return;
      }

      const redirect = this.$route?.query?.redirect || this.authDialogRedirect;
      const redirectToInfluitive = /^\/?api\/dashboard-redirect/.test(redirect);

      if (redirectToInfluitive) {
        window.location = redirect;
        this.$store.dispatch('closeAuthDialog');
        return;
      }

      this.$store.dispatch('setAuthStep', { authStep: 'accountConfirmation' });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_set-social-sites';
</style>
