<template>
  <div class="subscribe-trigger">
    <button-component
      class="subscribe-trigger__button"
      @click="opensLeadGenFormInDialog"
    >
      Subscribe
    </button-component>
  </div>
</template>

<script>
import ButtonComponent from '@/components/Global/ButtonComponent.vue';

export default {
  name: 'SubscribeTrigger',
  components: {
    ButtonComponent,
  },
  methods: {
    opensLeadGenFormInDialog() {
      this.$store.dispatch('openLeadGenFormInDialog', true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/components/_subscribe-trigger';
</style>
