<template>
  <form-component
    novalidate="true"
    class="login-with-email"
    @submit.prevent="tryToLogIn"
  >
    <inline-validation-error
      v-if="loginAttemptFailed"
      :validation-text="loginAttemptFailedErrorMessage"
    />
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.email.$invalid }"
    >
      <validation-error
        ref="email"
        class="validation-error--label"
        :model="email"
        :validator="$v.email"
      />
      <text-input
        id="email"
        v-model="email"
        name="email"
        input-type="email"
        class="text-input--full-width text-input--lowercase"
        autocapitalize="off"
        @input="changeLoginAttemptStatus"
      >Email</text-input>
    </div>
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.password.$invalid }"
    >
      <validation-error
        ref="password"
        class="validation-error--label"
        :model="password"
        :validator="$v.password"
      />
      <password-input
        id="password"
        v-model="password"
        name="password"
        class="password-input--full-width"
        @input="changeLoginAttemptStatus"
      />
    </div>
    <recaptcha-component ref="recaptcha" @verify="onCaptchaVerified" />
    <loading-button
      class="login-with-email__button button--center"
      type="submit"
      :loading="tryingToLogIn"
      button-type="log in"
    >
      Log in
    </loading-button>
  </form-component>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import TextInput from '@/components/Inputs/TextInput.vue';
import PasswordInput from '@/components/Auth/PasswordInput.vue';
import ValidationError from '@/components/Global/ValidationError.vue';
import LoadingButton from '@/components/Global/LoadingButton.vue';
import RecaptchaComponent from '@/components/Auth/RecaptchaComponent.vue';
import InlineValidationError from '@/components/Auth/InlineValidationError.vue';
import dataLayerMixin from '@/mixins/data-layer-mixin';

export default {
  name: 'LoginWithEmail',
  components: {
    TextInput,
    PasswordInput,
    ValidationError,
    LoadingButton,
    RecaptchaComponent,
    InlineValidationError,
  },
  mixins: [dataLayerMixin],
  data() {
    return {
      tryingToLogIn: false,
      loginAttemptFailed: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    ...mapGetters([
      'authDialogRedirect',
      'siteHasFeature',
      'currentUser',
      'logInEmail',
      'logInPassword',
    ]),
    email: {
      get() {
        return this.logInEmail;
      },
      set(value) {
        this.$store.commit('setEmail', value);
      },
    },
    password: {
      get() {
        return this.logInPassword;
      },
      set(value) {
        this.$store.commit('setPassword', value);
      },
    },
    formIsInvalid() {
      return this.$v.$anyError;
    },
    redirectedFromModeration() {
      return this.$route.query.redirect && /moderation/.test(this.$route.query.redirect);
    },
    loginAttemptFailedErrorMessage() {
      return 'The email and password you entered did not match our records.';
    },
  },
  mounted() {
    if (this.redirectedFromModeration) {
      this.$store.dispatch('addToastNotification', {
        toastType: 'error',
        description: 'You must be logged in to access moderation',
      });
    }
  },
  methods: {
    loginSuccess() {
      this.setDataLayer();
      this.pushDataLayer();
      this.setTrackingInAC(this.email);

      this.$store.dispatch('clearLogInForm');

      const redirect = this.$route?.query?.redirect || this.$route?.query?.redirect_to || this.authDialogRedirect;
      const redirectToInfluitive = /^\/?api\/dashboard-redirect/.test(redirect);
      const redirectedFromWP = /admin|wp-admin|dashboard/.test(redirect) && (redirect && (redirect.startsWith('/') || (new URL(redirect)).origin === (new URL(window.location)).origin));

      if (redirectToInfluitive) {
        window.location = redirect;
        return;
      }

      if (this.authDialogRedirect) {
        this.$store.dispatch('closeAuthDialog');
        this.$router.push(this.authDialogRedirect);
        this.$store.dispatch('clearAuthDialogRedirect');
      } else if (this.$store.state.authOpen) {
        this.$store.dispatch('closeAuthDialog');
      } else if (this.redirectedFromModeration) {
        window.location = `${window.location.origin}/moderation`;
        return;
      } else if (redirectedFromWP) {
        window.location = redirect;
      } else {
        this.$router.push(this.$route.query.redirect || { name: 'home' });
      }

      const { username } = this.currentUser;

      if (username) {
        this.$store.dispatch('addToastNotification', {
          toastType: 'success',
          description: `Welcome back ${username}!`,
        });
      }

      const userHasNeverBeenLoggedInBeforeThis = !this.currentUser.lastLoginAt;

      if (this.siteHasFeature('social_network')) {
        if (userHasNeverBeenLoggedInBeforeThis) {
          this.$store.dispatch('setFirstTimeLoggingIntoSHN', true);
          this.$store.dispatch('openAuthDialog', { authStep: 'setUsername' });
        } else if (!this.currentUser.hasDisorder) {
          this.$store.dispatch('openAuthDialog', { authStep: 'requestDisorderDemographicInfo' });
        } else if (!this.currentUser.hasSocialSite) {
          this.$store.dispatch('openAuthDialog', { authStep: 'setSocialSites' });
        }
      }

      this.$apolloProvider.phoenixSocket.connect();
    },
    loginFailed() {
      this.loginAttemptFailed = true;
    },
    changeLoginAttemptStatus() {
      this.loginAttemptFailed = false;
    },
    onCaptchaVerified(recaptchaToken) {
      return this.$auth.logIn({
        email: this.email,
        password: this.password,
        remember_me: this.rememberMe,
        recaptcha_token: recaptchaToken,
      })
        .then((response) => {
          if (response.status === 'success') {
            this.loginSuccess();
          } else {
            this.loginFailed();
          }
        })
        .catch((error) => {
          this.$logger.error(error);
        }).finally(() => {
          this.tryingToLogIn = false;
        });
    },
    tryToLogIn() {
      this.tryingToLogIn = true;

      this.$v.$touch();

      this.$refs.email.checkErrors();
      this.$refs.password.checkErrors();

      if (this.$v.$invalid) {
        this.tryingToLogIn = false;
        return;
      }
      this.$refs.recaptcha.execute();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_login-with-email';
</style>

<docs>
Form for users to login with email.  Part of registration .

</docs>
