<template>
  <div
    id="app"
    class="app"
  >
    <site-wrapper class="app__site-wrapper">
      <skip-links /> <!-- must stay at the top for tab navigation -->
      <auth-dialog v-if="$store.state.authOpen" />
      <image-upload-dialog v-if="$store.state.imageUploads.imageUploadOpen" />
      <the-header />
      <div class="app__wrapper">
        <content-driver-banner v-if="hasContentDriverBanner" />
        <crisis-response-banner v-if="hasCrisisResponseBanner" />
        <lead-gen-pop-up
          v-if="openLeadGenPopUp || openLeadGenFormViaSubscribeTrigger"
          :open-in-dialog="openLeadGenFormViaSubscribeTrigger"
          @close="closeLeadGenForm"
        />
        <router-view ref="routerView" :key="$route.fullPath" @loaded="handleOnLoad" />
        <toast-notifications v-if="$store.getters.anyToasts" />
        <profile-tooltip v-if="$store.getters.showTooltip" />
        <conversation-pop-over v-if="$store.getters.popOverOpen" />
      </div>
      <lazy-hydrate when-visible>
        <the-footer />
      </lazy-hydrate>
    </site-wrapper>
  </div>
</template>

<script>
/*
 * 1. This is meant to serve as a minimal solution for responsive components.
 *    It can be accessed from any component in the app at
 *    `this.$root.$children[0].windowWidth`. We might consider replacing this
 *    with the vue-mq plugin if we find that we have more robust viewport
 *    detection needs.
 */

import debounce from 'lodash/debounce';
import SkipLinks from '@/components/Accessibility/SkipLinks.vue';
import SiteWrapper from '@/components/Global/SiteWrapper.vue';
import TheHeader from '@/components/Header/TheHeader.vue';
import ProfileTooltip from '@/components/User/ProfileTooltip.vue';
import ToastNotifications from '@/components/ToastNotifications/ToastNotifications.vue';
import AuthDialog from '@/components/Auth/AuthDialog.vue';
import TheFooter from '@/components/Footer/TheFooter.vue';
import ImageUploadDialog from '@/components/ImageUpload/ImageUploadDialog.vue';
import ConversationPopOver from '@/components/PrivateMessaging/ConversationPopOver.vue';
import { mapGetters } from 'vuex';
import dataLayerMixin from '@/mixins/data-layer-mixin';
import CrisisResponseBanner from '@/components/Header/CrisisResponseBanner.vue';
import ContentDriverBanner from '@/components/Header/ContentDriverBanner.vue';
import LeadGenPopUp from '@/components/Global/LeadGenPopUp.vue';
import Cookies from 'js-cookie';
import axios from 'axios';
import experimentsMixin from '@/mixins/experiments-mixin';

export default {
  components: {
    SkipLinks,
    SiteWrapper,
    TheHeader,
    ProfileTooltip,
    ToastNotifications,
    AuthDialog,
    TheFooter,
    ImageUploadDialog,
    ConversationPopOver,
    CrisisResponseBanner,
    ContentDriverBanner,
    LeadGenPopUp,
  },
  mixins: [dataLayerMixin, experimentsMixin],
  data() {
    return {
      firstPageView: true,
      windowWidth: process.browser ? window.innerWidth : null, /* [1] */
      openLeadGenPopUp: false,
    };
  },
  computed: {
    ...mapGetters([
      'dataLayer',
      'visitorType',
      'siteHasFeature',
      'userIsLoggedIn',
      'userWasReferredByEmail',
      'userHasSubscribed',
      'authDialogWasShown',
      'hasLeadGenPopUpDisabledCookie',
      'subscriptionWallIsActive',
      'openLeadGenFormViaSubscribeTrigger',
    ]),
    hasCrisisResponseBanner() {
      const crisisResponseBanner = this.$site?.settings.crisis_response_banner;
      return crisisResponseBanner && crisisResponseBanner.trim().length > 0;
    },
    hasContentDriverBanner() {
      return this.$site?.settings.content_driver_banner;
    },
    authIsVisible() {
      return this.authDialogWasShown || this.$route.name === 'register';
    },
  },
  watch: {
    $route() {
      if (!process.browser) return;
      this.$nextTick(() => {
        this.triggerLeadGenPopUp('page');
        window.setTimeout(this.resetFocus, 0);
      });
    },
  },
  beforeCreate() {
    if (this.$route?.query?.utm_medium === 'email') {
      this.$store.dispatch('setUserWasReferredByEmail');
    }
  },
  created() {
    this.setSubscriptionWallStatus();
  },
  mounted() {
    const userJustVerified = this.$route.fullPath.includes('just-verified');
    const userResetPassword = this.$route.fullPath.includes('reset_key');
    const isRareDisease = this.siteHasFeature('rare_disease');

    this.pushDataLayer();
    window.setTimeout(() => this.triggerLeadGenPopUp('time'), 30000);
    window.setTimeout(this.sendNexusReady, 0);
    window.setTimeout(this.detectAdblock, 500);
    window.addEventListener('scroll', debounce(this.handleScroll, 1000));
    window.addEventListener('resize', debounce(this.handleResize, 250));
    if (userJustVerified && !isRareDisease) {
      this.$store.dispatch('openAuthDialog', { authStep: 'accountConfirmation' });
    }
    if (userJustVerified && isRareDisease) {
      this.$store.dispatch('openAuthDialog', { authStep: 'accountConfirmationWithNoCTA' });
    }
    if (userResetPassword) {
      this.$store.dispatch('openAuthDialog', { authStep: 'resetPassword' });
    }

    // if OneTrust banner isn't already closed
    if (!Cookies.get('OptanonAlertBoxClosed')) {
      document.addEventListener('focusout', this.oneTrustBannerFocusControl);
    }
    this.oneTrustGroupsUpdated();
    window.addEventListener('OneTrustGroupsUpdated', this.oneTrustGroupsUpdated);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', debounce(this.handleScroll, 1000));
    window.removeEventListener('OneTrustGroupsUpdated', this.oneTrustGroupsUpdated);
  },
  methods: {
    closeLeadGenForm() {
      this.$store.dispatch('openLeadGenFormInDialog', false);
      this.openLeadGenPopUp = false;
    },
    oneTrustGroupsUpdated() {
      this.$store.dispatch('updateComplianceContext', this.$oneTrust.getCurrentCategoryIdsString());
    },
    detectAdblock() {
      if (!((document.querySelector('.ad-slot.ad-detect') || {}).offsetParent)) {
        this.$store.dispatch('setHasAdblock', true);
        if (!Cookies.get('adblock')) {
          Cookies.set('adblock', 'enabled');
          // just need to hit an endpoint so the cookie gets sent & recorded
          axios.get('/api/health');
        }
      } else if (Cookies.get('adblock') === 'recorded') {
        Cookies.set('adblock', 'disabled');
        axios.get('/api/health');
      }
    },
    handleOnLoad(dataLayer) {
      this.updateDataLayer(dataLayer);
    },
    resetFocus() {
      // Sets focus to #app, called whenever the route is changed.
      // Reference: https://marcus.io/blog/improved-accessible-routing-vuejs
      // Get #app with this.$el
      const rootElement = this.$el;
      if (!rootElement) {
        const routeName = this.$route.name;
        const routePath = this.$route.path;
        this.$logger.warn(`watcher ran before this.$el exists at ${routeName} ${routePath}`);
        return;
      }
      // Make rootElement programmatically focussable
      rootElement.setAttribute('tabindex', '-1');
      rootElement.focus();
      // Remove tabindex from rootElement on blur, remove the listener when envoked.
      // Reason: https://axesslab.com/skip-links/#update-3-a-comment-from-gov-uk
      rootElement.addEventListener('blur', (event) => {
        event.target.removeAttribute('tabindex');
      }, { once: true });
    },
    triggerLeadGenPopUp(triggeredVariant) {
      if (
        !this.hasLeadGenPopUpDisabledCookie
        && !this.userWasReferredByEmail
        && !this.userIsLoggedIn
        && !this.authIsVisible
        && !this.$store.state.subscriptionWallIsActive
      ) {
        this.bucket('lead_gen_pop_up').then((bucketedVariant) => {
          if (bucketedVariant === triggeredVariant) {
            this.openLeadGenPopUp = true;
          }
        });
      }
    },
    updateDataLayer(dataLayer) {
      this.setDataLayer(dataLayer);
      // Client only
      if (process.browser) {
        const routeNeedsAdditionalFacebookParams = this.$route.query.fbclid
          && (!this.$route.query.utm_source || !this.$route.query.utm_medium);

        if (routeNeedsAdditionalFacebookParams) {
          const query = { ...this.$route.query };
          query.utm_source = this.$route.query.utm_source || 'facebook.com';
          query.utm_medium = this.$route.query.utm_medium || 'organic';
          this.$router.replace({ query: { ...query } });
          return; // $router.replace will trigger updateDataLayer a second time so we bail here to prevent duplicate pageview events.
        }
        this.pushDataLayer();
        this.$nextTick(() => {
          this.$snowplow.trackPageView();
          if (!this.firstPageView) { // Do not send on the first page view
            this.$gtm.trackView(this.$route.name, this.$route.fullPath);
          } else {
            this.firstPageView = false;
          }
        });
      }
    },
    handleScroll() {
      this.setScrollPosition(document.body);
    },
    setScrollPosition(el) {
      const parent = el.parentNode;
      const scrollPercentage = Math.round(
        // eslint-disable-next-line no-mixed-operators
        (el.scrollTop || parent.scrollTop) / (parent.scrollHeight - parent.clientHeight) * 100,
      );
      const scrollDepth = Math.round(scrollPercentage / 10) * 10;

      this.$snowplow.enableScrollTracking({
        data: {
          scroll_depth: scrollDepth,
        },
      });
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    oneTrustBannerFocusControl(event) {
      const oneTrustBanner = document.querySelector('#onetrust-consent-sdk');

      // if the banner doesn't exist for a reason other than having been accepted already
      if (!oneTrustBanner) {
        document.removeEventListener('focusout', this.oneTrustBannerFocusControl);
        return;
      }

      // if element that focus is moving to is not contained within the banner
      if (!oneTrustBanner.contains(event.relatedTarget)) {
        this.resetFocus();
        document.removeEventListener('focusout', this.oneTrustBannerFocusControl);
      }
    },
    setSubscriptionWallStatus() {
      const showSubscriptionWall = this.$site.settings.subscription_wall
        && !this.userHasSubscribed
        && !this.userIsLoggedIn
        && !this.userWasReferredByEmail;
      this.$store.dispatch('setSubscriptionWallStatus', showSubscriptionWall);
    },
  },
};
</script>

<style lang="scss"> // no scoped
@import 'stylesheets/components/app';
@import 'stylesheets/components/layout';
@import 'stylesheets/components/content';

// Generic
@import '~normalize.css/normalize';
@import 'stylesheets/generic/box-sizing';
@import 'stylesheets/generic/fonts';
@import 'stylesheets/generic/reset';

// Base (Elements)
@import 'stylesheets/base/document';
@import 'stylesheets/base/forms';
@import 'stylesheets/base/images';
@import 'stylesheets/base/tables';

// Objects
@import 'stylesheets/objects/button-reset';
@import 'stylesheets/objects/button';
@import 'stylesheets/objects/disclaimer-text';
@import 'stylesheets/objects/headings';
@import 'stylesheets/objects/icon';
@import 'stylesheets/objects/inline-list';
@import 'stylesheets/objects/links';
@import 'stylesheets/objects/list-reset';
@import 'stylesheets/objects/reaction-icon-background.scss';
@import 'stylesheets/objects/text-button';
@import 'stylesheets/objects/text-input';
@import 'stylesheets/objects/textarea';

// Overrides
@import 'stylesheets/overrides/alignment';
@import 'stylesheets/overrides/hidden';
@import 'stylesheets/overrides/visible';
@import 'stylesheets/overrides/visually-hidden';

#ad-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
