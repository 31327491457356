<template>
  <button-component
    class="button--block email-login-button"
    data-test-id="email-login-button"
    @click="$emit('click')"
  >
    <icon-component
      name="envelope"
      class="button__icon"
    />
    {{ buttonText }}
  </button-component>
</template>

<script>
import ButtonComponent from '@/components/Global/ButtonComponent.vue';

export default {
  name: 'EmailLoginButton',
  components: {
    ButtonComponent,
  },
  props: {
    buttonText: {
      type: String,
      default: 'Log in with email',
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_email-login-button';
</style>

<docs>

Button for logging in with email for new registration flow

</docs>
