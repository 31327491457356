<template>
  <button
    class="button"
    @click="handleClick"
  >
    <slot>Button Text</slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: {
  /**  buttonType is being
  * used for snowplow button
  * tracking below.
  * this is not a replacement
  * for the button type attr
  */
    buttonType: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClick(event) {
      this.$snowplow.trackButtonEvent({
        data: {
          type: this.buttonType,
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
      this.$emit('click', event);
    },
  },
};
</script>

<docs>
</docs>
