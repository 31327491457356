import Vue from 'vue';
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';
import VueGtm from 'vue-gtm';
import CenteredLayout from '@/layouts/CenteredLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import SidebarLayout from '@/layouts/SidebarLayout.vue';
import FullBleedLayout from '@/layouts/FullBleedLayout.vue';
import InlineMention from '@/components/Mention/InlineMention.vue';
import FormComponent from '@/components/Global/FormComponent.vue';
import IconComponent from '@/components/Global/IconComponent.vue';
import AppLink from '@/components/Global/AppLink.vue';
import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import ContentRenderer from '@/components/Content/ContentRenderer.vue';
import ClientOnly from 'vue-client-only';
import LazyHydrate from 'vue-lazy-hydration';
import SnowplowMixin from '@/mixins/snowplow-mixin';
import { host } from '@/utils/host-helpers';
import { createStore } from './store/index';
import { createRouter } from './router/index';
import navigationGuards from './router/navigation-guards';
import createProvider from './graphql/apollo';
import App from './App.vue';
import GDFP from './plugins/gdfp';
import Snowplow from './plugins/snowplow';
import ReactionsMediator from './plugins/reactions-mediator';
import currentSite from './plugins/current-site';
import siteList from './plugins/site-list';
import OneTrust from './plugins/one-trust';
import DatadogRum from './plugins/datadog-rum';
import logger, { initDatadog } from './plugins/logger';
import { authInstaller } from './plugins/auth';
import { dynamicRoutes } from './router/dynamic-routes';
import defaultMeta from './metadata';
import { decodeHtml } from './filters/decode-html';
import 'wicg-inert'; // Polyfill for the inert attribute. Used for carousel accessibility.

// eslint-disable-next-line global-require
if (process.browser) require('./registerServiceWorker');

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(Vuelidate);

// Global layout components
Vue.component('CenteredLayout', CenteredLayout);
Vue.component('DefaultLayout', DefaultLayout);
Vue.component('SidebarLayout', SidebarLayout);
Vue.component('FullBleedLayout', FullBleedLayout);

// Global components
Vue.component('IconComponent', IconComponent);
Vue.component('LoadingSpinner', LoadingSpinner);
Vue.component('ClientOnly', ClientOnly);
Vue.component('LazyHydrate', LazyHydrate);
Vue.component('FormComponent', FormComponent);
Vue.component('AppLink', AppLink);
// InlineMention must be registered globally as it is injected dynamically as a template
Vue.component('InlineMention', InlineMention);
// ContentRenderer must be registered globally as it is used recursively, circular dependencies
// get pretty weird with dynamically requiring components.
Vue.component('ContentRenderer', ContentRenderer);
// Global Mixins
Vue.mixin(SnowplowMixin);

// The inject function should be used to install any plugin that carries state
// of any kind, this ensures that the correct state is injected on the Vue.prototype during SSR.
// borrowed with love from here: https://github.com/vuejs/vue-ssr-docs/issues/224
function inject(appOptions, key, value) {
  // pass the property to the root Vue instance's options
  // eslint-disable-next-line no-param-reassign
  appOptions[key] = value;

  Vue.use(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (!Vue.prototype.hasOwnProperty(key)) {
      // register a Vue.prototype[key] getter that retrieves the value from the root options
      Object.defineProperty(Vue.prototype, key, {
        get() {
          return this.$root.$options[key];
        },
      });
    }
  });
}

export async function createApp({
  context,
  beforeApp = () => {},
  afterApp = () => {},
}) {
  const store = createStore(context);
  const apolloProvider = await createProvider(context, store);
  const router = await createRouter(context, apolloProvider);

  Vue.use(ReactionsMediator, { apolloProvider, context });
  if (process.browser) {
    Vue.use(GDFP, store, router);
    Vue.use(VueGtm, {
      id: 'GTM-TNDPLXL',
      enabled: true,
      debug: false,
      loadScript: true,
    });
    Vue.use(Snowplow, { store });
    await dynamicRoutes(router, apolloProvider);
  }

  await beforeApp({
    router,
    store,
  });

  const appOptions = {
    router,
    store,
    apolloProvider,
    filters: {
      decodeHtml,
    },
    context,
    computed: {
      hostname() {
        return this.$options.context.host || this.$options.context.req.headers.host;
      },
    },
    // Server-side only
    // This will be called by the server renderer automatically, and will complete before rendering
    async serverPrefetch() {
      await dynamicRoutes(router, apolloProvider, this.$site.id, this.$store);
      await this.$store.dispatch('checkForCurrentUser', this);
    },
    beforeCreate() {
      navigationGuards(router, store);
    },
    mounted() {
      initDatadog(this.$store.getters.siteDataLayer);

      // If we didn't already do it on the server, fetch the currentUser here
      if (!this.$store.state.currentUser.loaded) {
        this.$logger.info('user was not loaded during SSR');
        this.$store.dispatch('checkForCurrentUser', this);
      }

      this.$store.dispatch('updateGlobalContext', {
        site: this.$site,
      });

      this.$store.dispatch('initUserContext');
    },
    metaInfo() {
      const site = this.$site;
      let canonical = `https://${host(context)}`;
      if (this.$route.path !== '/') {
        canonical += this.$route.path;
      }

      const meta = {
        title: site.description,
        titleTemplate: `%s | ${site.name}`,
        meta: [...defaultMeta(site, context.url)],
        link: [
          { rel: 'manifest', href: '/api/manifests/app.webmanifest', crossorigin: 'use-credentials' },
          { vmid: 'canonical', rel: 'canonical', href: canonical },
          { rel: 'apple-touch-icon', href: '/images/logo_avatar@192x192.png' },
        ],
      };

      if (site.settings.google_search_console_verification) {
        meta.meta.push(
          {
            name: 'google-site-verification',
            content: site.settings.google_search_console_verification,
          },
        );
      }

      return meta;
    },
    render: (h) => h(App),
  };

  await currentSite(appOptions, inject);
  await siteList(appOptions, inject);
  await authInstaller(appOptions, inject);
  await logger(appOptions, inject);
  // Must be after currentSite
  await OneTrust(appOptions, inject);

  let hostname = '';

  if (context.req && context.req.headers && context.req.headers.host) {
    hostname = context.req.headers.host;
  }

  await DatadogRum(appOptions, hostname, context.ddEnv, context.ddVersion, inject);

  const app = new Vue(appOptions);

  const result = {
    app, router, store, apolloProvider,
  };

  await afterApp(result);

  return result;
}
