import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from '@/store/storage';
import merge from 'deepmerge';
import { uuidv4 } from '@/utils/utils';
import post from './modules/post';
import gdfp from './modules/gdfp';
import navigation from './modules/navigation';
import currentUser from './modules/current-user';
import accessibility from './modules/accessibility';
import profileTooltips from './modules/profile-tooltips';
import toastNotifications from './modules/toast-notifications';
import imageUploads from './modules/image-uploads';
import configurationOptions from './modules/configuration-options';
import privateMessaging from './modules/private-messaging';
import experiments from './modules/experiments';
import dataLayer from './modules/data-layer';
import snowplowContexts from './modules/snowplow-contexts';
import websocket from './modules/websocket-connection';
import currentSite from './modules/current-site';
import forums from './modules/forums';
import jsonLd from './modules/json-ld';
import siteList from './modules/site-list';
import logInForm from './modules/logInForm';
import dynamicRoutes from './modules/dynamic-routes';

Vue.use(Vuex);

const plugins = [];

plugins.push(createPersistedState);

function setPersistedStateSSR(store, context) {
  if (!context.req || !context.req.cookies || !context.req.cookies.vuex) {
    return;
  }
  const persistedState = JSON.parse(context.req.cookies.vuex);
  const newState = merge(store.state, persistedState);
  store.replaceState(newState);
}

export function createStore(context) {
  const store = new Vuex.Store({
    modules: {
      accessibility: accessibility(),
      currentUser: currentUser(),
      post: post(),
      gdfp: gdfp(),
      navigation: navigation(),
      profileTooltips: profileTooltips(),
      toastNotifications: toastNotifications(),
      imageUploads: imageUploads(),
      configurationOptions: configurationOptions(),
      privateMessaging: privateMessaging(),
      experiments: experiments(),
      dataLayer: dataLayer(),
      snowplowContexts: snowplowContexts(),
      websocket: websocket(),
      currentSite: currentSite(context),
      forums: forums(),
      jsonLd: jsonLd(),
      siteList: siteList(),
      logInForm: logInForm(),
      dynamicRoutes: dynamicRoutes(),
    },
    state: () => ({
      authOpen: false,
      authForm: 'logIn',
      authDialogRedirect: '',
      authDialogText: '',
      authDialogHeading: '',
      authInitiator: null,
      hasAdblock: false,
      emailAlreadySent: false,
      isUGC: false,
      profileMenuOpen: false,
      returning: false,
      galleryCarousel: {
        open: false,
        galleryId: '',
        initialSlide: 0,
      },
      is404: false,
      prev404Urls: [],
      isPreviewError: false,
      focusSearch: false,
      pageViewId: uuidv4(),
      acceptsWebp: false,
      ProgressIndicatorSteps: 0,
      userMousing: true,
      fastTrackToContent: false,
      subscriptionWallIsActive: false,
      openLeadGenForm: false,
      engagementSubWallIsActive: false,
    }),
    getters: {
      hasAdblock: (state) => state.hasAdblock,
      is404: (state) => state.is404,
      isPreviewError: (state) => state.isPreviewError,
      profileMenuOpen: (state) => state.profileMenuOpen,
      authForm: (state) => state.authForm,
      authInitiator: (state) => state.authInitiator,
      emailAlreadySent: (state) => state.emailAlreadySent,
      isUGC: (state) => state.isUGC,
      authDialogRedirect: (state) => state.authDialogRedirect,
      focusSearch: (state) => state.focusSearch,
      pageViewId: (state) => state.pageViewId,
      ProgressIndicatorSteps: (state) => state.ProgressIndicatorSteps,
      userMousing: (state) => state.userMousing,
      fastTrackToContent: (state) => state.fastTrackToContent,
      subscriptionWallIsActive: (state) => state.subscriptionWallIsActive,
      openLeadGenFormViaSubscribeTrigger: (state) => state.openLeadGenForm,
      engagementSubWallIsActive: (state) => state.engagementSubWallIsActive,
    },
    mutations: {
      setHasAdblock(state, bool) {
        state.hasAdblock = bool;
      },
      setAuthInitiator(state, data) {
        state.authInitiator = data;
      },
      setAuthOpen(state, data) {
        state.authOpen = data;
      },
      setAuthForm(state, data) {
        state.authForm = data;
      },
      setAuthDialogRedirect(state, data) {
        state.authDialogRedirect = data;
      },
      setOrClearAuthDialogText(state, data) {
        state.authDialogText = data || '';
      },
      setOrClearAuthDialogHeading(state, data) {
        state.authDialogHeading = data || '';
      },
      setEmailSent(state, data) {
        state.emailAlreadySent = data;
      },
      setIsUGC(state, data) {
        state.isUGC = data || false;
      },
      setProfileMenuOpen(state, data) {
        state.profileMenuOpen = data;
      },
      setGalleryCarousel(state, data) {
        state.galleryCarousel = Object.assign(state.galleryCarousel, data);
      },
      setReturningUser(state, data) {
        state.returning = data;
      },
      setIs404(state, data) {
        state.is404 = data;
      },
      setFocusSearch(state, data) {
        state.focusSearch = data;
      },
      setPageViewId(state, data) {
        state.pageViewId = data;
      },
      setIsPreviewError(state, data) {
        state.isPreviewError = data;
      },
      setProgressIndicatorSteps(state, data) {
        state.ProgressIndicatorSteps = data || 3;
      },
      setUserMousing(state, data) {
        state.userMousing = data;
      },
      setFastTrackToContent(state, data) {
        state.fastTrackToContent = data;
      },
      setSubscriptionWallStatus(state, data) {
        state.subscriptionWallIsActive = data;
      },
      openLeadGenForm(state, data) {
        state.openLeadGenForm = data;
      },
      setEngagementSubWallStatus(state, data) {
        state.engagementSubWallIsActive = data;
      },
      setPrev404Urls(state, data) {
        state.prev404Urls.push(data);
      },
    },
    actions: {
      setAuthInitiator({ commit }, data) {
        commit('setAuthInitiator', data);
      },
      setHasAdblock({ commit }, data) {
        commit('setHasAdblock', data);
      },
      setAuthOpen({ commit }, data) {
        commit('setAuthOpen', data);
        // Reset the auth form to the default state
        if (data === false) {
          commit('setAuthForm', 'logIn');
          commit('setAuthInitiator', null);
        }
      },
      closeAuthDialog({ commit, dispatch }) {
        commit('setIsUGC', false);
        dispatch('setAuthOpen', false);
        dispatch('clearLogInForm');
        commit('setFastTrackToContent', false);
      },
      setAuthForm({ commit }, data) {
        commit('setAuthForm', data);
      },
      setAuthDialogRedirect({ commit }, data) {
        commit('setAuthDialogRedirect', data);
      },
      clearAuthDialogRedirect({ commit }) {
        commit('setAuthDialogRedirect', '');
      },
      setProfileMenuOpen({ commit }, data) {
        commit('setProfileMenuOpen', data);
      },
      openGalleryCarousel({ commit }, data) {
        commit('setGalleryCarousel', { ...{ open: true }, ...data });
      },
      closeGalleryCarousel({ commit }) {
        commit('setGalleryCarousel', {
          open: false,
          galleryId: '',
          galleryItems: [],
        });
      },
      setReturningUser({ commit }, data) {
        commit('setReturningUser', data);
      },
      closeAllOverlays({ dispatch }) {
        dispatch('setAuthOpen', false);
        dispatch('setNavOpen', false);
      },
      setIs404({ commit }, data) {
        commit('setIs404', data);
      },
      setIsPreviewError({ commit }, data) {
        commit('setIsPreviewError', data);
      },
      userLogOut({ commit }) {
        commit('setProfileMenuOpen', false);
        commit('addToastNotification', {
          toastType: 'success',
          description: 'You have been logged out, see you next time.',
        });
        commit('closeConversationPopOver');
      },
      setFocusSearch({ commit }, data) {
        commit('setFocusSearch', data);
      },
      resetPageViewId({ commit }) {
        commit('setPageViewId', uuidv4());
      },
      openAuthDialog({ commit, rootGetters, dispatch }, data = {}) {
        if (!rootGetters.siteHasFeature('Accounts')) return;
        commit('setAuthForm', data.authStep || 'logIn');
        dispatch('setAuthOpen', true);
        if (typeof data.initiator !== 'undefined') {
          commit('setAuthInitiator', data.initiator);
        }
        commit('setOrClearAuthDialogText', data.dialogText);
        commit('setOrClearAuthDialogHeading', data.dialogHeading);
        commit('setEmailSent', data.emailAlreadySent || false);
      },
      setAuthStep({ commit, rootGetters }, data = {}) {
        if (!rootGetters.siteHasFeature('Accounts')) return;

        commit('setAuthForm', data.authStep || 'logIn');
        commit('setOrClearAuthDialogText', data.dialogText);
        commit('setOrClearAuthDialogHeading', data.dialogHeading);
      },
      openLoginDialog({ dispatch }, data = {}) {
        const dataWithAuthStep = { ...data, authStep: 'logIn' };
        dispatch('openAuthDialog', dataWithAuthStep);
      },
      openRegisterDialog({ commit, dispatch }, data = {}) {
        let defaultNumberOfSteps = 0;
        if (this.getters.siteHasFeature('rare_disease')) defaultNumberOfSteps = 3;
        if (this.getters.siteHasFeature('social_network')) defaultNumberOfSteps = 5;

        const dataWithAuthStep = { ...data, authStep: 'register' };
        dispatch('openAuthDialog', dataWithAuthStep);
        commit('setIsUGC', data.isUGC);
        commit('setProgressIndicatorSteps', data.progressIndicatorSteps || defaultNumberOfSteps);
      },
      openLoginWithEmailDialog({ commit, rootGetters }, data = {}) {
        if (!rootGetters.siteHasFeature('Accounts')) {
          return;
        }
        commit('setAuthForm', 'loginWithEmail');
        commit('setOrClearAuthDialogText', data.dialogText);
      },
      openVerificationPrompt({ commit, dispatch }, data = {}) {
        const dataWithAuthStep = { ...data, authStep: 'verificationOptions' };
        commit('setIsUGC', true);
        dispatch('openAuthDialog', dataWithAuthStep);
      },
      openRequestPasswordReset({ dispatch }, data = {}) {
        const dataWithAuthStep = { ...data, authStep: 'requestPasswordReset' };
        dispatch('openAuthDialog', dataWithAuthStep);
      },
      setUserMousing({ commit }, data) {
        commit('setUserMousing', data);
      },
      setFastTrackToContent({ commit }, data) {
        commit('setFastTrackToContent', data);
      },
      setProgressIndicatorSteps({ commit }, data) {
        commit('setProgressIndicatorSteps', data);
      },
      setSubscriptionWallStatus({ commit }, data) {
        commit('setSubscriptionWallStatus', data);
      },
      openLeadGenFormInDialog({ commit }, data) {
        commit('openLeadGenForm', data);
      },
      setEngagementSubWallStatus({ commit }, data) {
        commit('setEngagementSubWallStatus', data);
      },
      setPrev404Urls({ commit }, data) {
        commit('setPrev404Urls', data);
      },
    },
    plugins,
  });

  if (!process.browser) {
    setPersistedStateSSR(store, context);
  }

  return store;
}
