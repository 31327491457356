<template>
  <loading-button
    class="resend-verification-text text-button text-button--link-style"
    :loading="tryingToSubmit"
    @click="resendVerificationText"
  >
    <slot>Resend Text</slot>
  </loading-button>
</template>

<script>
import LoadingButton from '@/components/Global/LoadingButton.vue';
import { RESEND_PHONE_VERIFICATION } from '@/graphql/mutations/user-mutations';

export default {
  name: 'ResendVerificationText',
  components: { LoadingButton },
  data() {
    return {
      tryingToSubmit: false,
    };
  },
  methods: {
    resendVerificationText() {
      this.tryingToSubmit = true;

      this.$apollo.mutate({
        mutation: RESEND_PHONE_VERIFICATION,
      }).then(() => {
        this.$store.dispatch('addToastNotification', {
          toastType: 'success',
          description: 'Code resent.',
        });
      }).catch((error) => {
        this.$logger.error(`[Resend Phone Verification] ${error}`);
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: `${error}`.replace('Error: GraphQL error: ', ''),
        });
      }).finally(() => {
        this.tryingToSubmit = false;
      });
    },
  },
};
</script>
