<template>
  <span>
    <button
      class="text-button text-button--link-style"
      @click="$store.dispatch('setAuthStep', { authStep: 'requestVerificationText' })"
    >
      verify via text message
    </button>.
  </span>
</template>

<script>
export default {
  name: 'VerifyViaTextMessageButton',
};
</script>
