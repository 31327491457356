<template>
  <div
    v-if="enabledOnSite"
    class="engagement-bar"
    :class="{ 'engagement-bar--condensed': isCondensed }"
  >
    <div class="engagement-bar__actions" data-test-id="engagement-bar-actions">
      <reaction-menu
        :id="objectId"
        :content-type="objectType"
      />
      <router-link
        v-if="theObject.commentStatus !== 'closed' && canComment"
        :to="commentLink"
        class="engagement-bar__link"
        :rel="rel"
      >
        <icon-component
          name="text-bubble"
          title="Comments"
          class="engagement-bar__action-icon engagement-bar__action-icon--comment"
        />
        <span
          class="engagement-bar__action-text"
        >{{ totalComments }} {{ textContent }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import ReactionMenu from '@/components/Reactions/ReactionMenu.vue';

import { config } from '@/configuration/nexus-config';

const typePath = {
  page: 'posts',
  post: 'posts',
  cas: 'posts',
  gallery: 'posts',
  graphic: 'posts',
  quiz: 'quizzes',
  story: 'stories',
  recipe: 'recipes',
  thread: 'forums',
};

const ugcTypes = ['thread'];

export default {
  name: 'EngagementBar',
  components: {
    ReactionMenu,
  },
  props: {
    /**
     * Type of object to be engaged with, valid types are:
     * post, thread, comment, cas, story
     */
    objectType: {
      type: String,
      required: true,
      validator: (value) => config.engagementTypes.includes(value),
    },
    theObject: {
      type: Object,
      required: true,
    },
    commentCount: {
      type: Number,
      required: true,
    },
    isCondensed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rel() {
      if (this.isUgc()) {
        return 'ugc nofollow';
      }
      return null;
    },
    enabledOnSite() {
      return this.$store.getters.siteHasFeature('Reactions');
    },
    canComment() {
      return this.$store.getters.siteHasFeature('Comments');
    },
    commentLink() {
      if (typePath[this.objectType] === 'forums') {
        return `/${typePath[this.objectType]}/${this.theObject.slug}#forum-thread__discussion`;
      }

      if (this.theObject.permalink) {
        return `/${this.theObject.permalink}#comment-thread`;
      }

      return `/${typePath[this.objectType]}/${this.theObject.slug}#comment-thread`;
    },
    totalComments() {
      if (!this.commentCount) {
        return 0;
      }
      return this.commentCount;
    },
    textContent() {
      if (this.objectType === 'thread') {
        return 'replies';
      }
      return 'comments';
    },
    objectId() {
      return `${this.theObject.id}`;
    },
  },
  methods: {
    isUgc() {
      return ugcTypes.includes(this.objectType);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_engagement-bar';
</style>

<docs>
</docs>
