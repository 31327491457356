<template>
  <div
    :id="id"
    ref="ad"
    class="ad-slot"
    :class="classes"
  />
</template>

<script>

import kebabCase from '@/utils/kebab-case';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'AdSlot',
  props: {
    /**
     * The mapping the slot should use. A corresponding mapping must be defined
     * in plugins/gdfp.js
     */
    mapping: {
      type: String,
      default: '',
    },
    /**
     * This is a string because it is coming from JSON which encodes booleans as
     * strings. Never use this prop directly, always use the dynamicBoolean computed.
     */
    dynamic: {
      type: String,
      default: 'false',
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    /**
     * This prop is unused in this component. It is data for ad sections
     * that is used in SidebarAds.vue.
     */
    displayInSidebar: {
      type: Boolean,
    },
  },
  data() {
    return {
      index: this.$store.state.gdfp.adsOnPage,
      observer: undefined,
      initialized: false,
    };
  },
  computed: {
    ...mapState('gdfp', ['empty_slots']),
    ...mapGetters(['dataLayer']),
    dynamicBoolean() {
      return this.dynamic === 'true';
    },
    id() {
      return `gdfp_${this.index}`;
    },
    adUnit() {
      return `/${this.$gdfp.network_id}/${this.dataLayer.ad_prefix}/${this.dataLayer.post_type}`;
    },
    sizeMapping() {
      try {
        return window.googletag.mappedSizes[this.mapping];
      } catch (e) {
        this.$logger.error(`Mappings for ${this.mapping} are not defined`);
        return null;
      }
    },
    adsEnabled() {
      return process.browser && this.$gdfp.adsEnabled();
    },
    size() {
      return this.sizeMapping ? this.sizeMapping.slice(-1)[0][1] : [];
    },
    classes() {
      const classes = [];
      if (this.mapping) {
        // kebab-case the slot mapping for use as a BEM class
        classes.push(`ad-slot--${kebabCase(this.mapping)}`);
      }
      if (this.dynamicBoolean || !this.mapping) {
        classes.push('ad-slot--dynamic');
      }
      return classes.join(' ');
    },
  },
  watch: {
    empty_slots(newValue) {
      if (!newValue.length && this.observer) {
        this.observer.disconnect();
      }
    },
  },
  created() {
    if (!this.adsEnabled) { return; }
    this.$store.dispatch('gdfp/incrementAdsOnPage');
  },
  mounted() {
    this.init();
    this.initialized = true;
  },
  beforeDestroy() {
    if (!this.observer) return;

    this.observer.disconnect();
  },
  methods: {
    init() {
      if (!this.adsEnabled) { return; }
      window.googletag.cmd.push(() => {
        this.define();
        this.lazyLoad();
      });
      if (this.sticky) {
        // eslint-disable-next-line global-require
        const stickyFill = require('stickyfilljs');
        stickyFill.add(this.$refs.ad);
      }
    },
    define() {
      const slot = window.googletag.defineSlot(this.adUnit, this.size, this.id);
      slot
        .defineSizeMapping(this.sizeMapping)
        .addService(window.googletag.pubads())
        .setTargeting('slot', this.id);

      this.$store.dispatch('gdfp/pushEmptySlots', slot);
      window.googletag.display(this.id);
    },
    lazyLoad() {
      this.observer = new IntersectionObserver((slots) => {
        slots.forEach((slot) => {
          if (!slot.isIntersecting) {
            return;
          }
          this.observer.unobserve(slot.target);

          this.display();
        });
      }, { rootMargin: '150px' });
      this.observer.observe(this.$refs.ad);
    },
    display() {
      // Do not use this in cmd.push as the reference can change when the callback runs
      const slotIndex = this.index;

      window.googletag.cmd.push(() => {
        const slot = window.googletag.pubads().getSlots()[slotIndex];
        this.$store.dispatch('gdfp/removeFromEmptySlots', slot);
        // This line requests a creative from DFP, this is what renders the ads.
        window.googletag.pubads().refresh([slot], { changeCorrelator: false });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_ad-slot';
</style>

<style lang="scss"> // no scoped - to reach inner div

.ad-slot--leaderboard > div {
  display: inline-block;
}
</style>

<docs>
This component is a placeholder for ads to be injected.

*AdSlot components must avoid doing anything besides rendering the placeholder markup
on the server side since most of this component's functionality depends on
window.googletag which is not available server-side.*
</docs>
