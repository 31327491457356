<template>
  <div class="social-login">
    <facebook-login
      v-if="showFacebookLogin"
      class="social-login__facebook"
      data-test-id="social-login-facebook"
      :button-text="facebookButtonText"
      @click="facebookLogIn()"
    />
    <google-login
      v-if="showGoogleLogin"
      class="social-login__google"
      data-test-id="social-login-google"
      :button-text-override="googleButtonText"
      @click="googleLogIn()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FacebookLogin from '@/components/Social/FacebookLogin.vue';
import GoogleLogin from '@/components/Social/GoogleLogin.vue';
import { SET_CURRENT_USER } from '@/graphql/mutations/current-user-mutation';
import dataLayerMixin from '@/mixins/data-layer-mixin';

export default {
  name: 'SocialLogin',
  components: {
    FacebookLogin,
    GoogleLogin,
  },
  mixins: [dataLayerMixin],
  props: {
    accountProviders: {
      type: Array,
      default: () => [
        'facebook',
        'google',
      ],
    },
    isRegistrationPage: {
      type: Boolean,
      default: () => false,
    },
    googleButtonText: {
      type: String,
      default: () => '',
    },
    facebookButtonText: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'authDialogRedirect',
      'siteHasFeature',
    ]),
    showGoogleLogin() {
      return this.accountProviders.includes('google');
    },
    showFacebookLogin() {
      return this.accountProviders.includes('facebook');
    },
    redirectedFromModeration() {
      return this.$route.query.redirect && /moderation/.test(this.$route.query.redirect);
    },
    userHasFirstAndLastName() {
      return this.currentUser.fname && this.currentUser.lname;
    },
    nextStepForNewUser() {
      if (this.siteHasFeature('rare_disease')) {
        return 'requestDisorderDemographicInfo';
      }
      if (this.siteHasFeature('social_network') && !this.userHasFirstAndLastName) {
        return 'fNameAndLName';
      }
      return 'setUsername';
    },
  },
  methods: {
    googleLogIn() {
      this.$auth.googleLogIn(this.callback);
    },
    facebookLogIn() {
      this.$auth.facebookLogIn(this.callback);
    },
    callback(socialAccountType) {
      if (typeof (parentLoginPayload) !== 'undefined') {
        // eslint-disable-next-line no-undef
        const { user } = parentLoginPayload;
        this.$store.dispatch('setCurrentUser', user);
        this.$store.dispatch('setLoggedInFromSocial');

        this.setDataLayer();
        this.pushDataLayer();
        this.setTrackingInAC(user.email);

        this.$snowplow.connectSocialAccountTracking({
          data: {
            connected_account: socialAccountType,
          },
        });
        this.cleanUp();
      } else {
        this.$store.dispatch('checkForCurrentUser', this).then(this.cleanUp);
      }
    },
    cleanUp() {
      if (!this.$store.state.currentUser.loggedIn) { return; }
      this.$emit('authed');

      const { username } = this.currentUser;
      const tempUsername = `CommunityMember${this.currentUser.id}`;
      const siteIsShn = this.siteHasFeature('social_network');
      const redirect = this.$route?.query?.redirect || this.authDialogRedirect;
      const redirectToInfluitive = /^\/?api\/dashboard-redirect/.test(redirect);

      if (!username) {
        this.$apollo.mutate({
          mutation: SET_CURRENT_USER,
          variables: {
            username: tempUsername,
          },
        }).then(({ data }) => {
          this.$store.dispatch('setCurrentUser', data.updateUser);
          if (this.siteHasFeature('social_network')) {
            this.$store.dispatch('setProgressIndicatorSteps', 4);
          }
          const action = this.$store.state.authOpen ? 'setAuthStep' : 'openAuthDialog';
          this.$store.dispatch(action, { authStep: this.nextStepForNewUser });
        });
      } else if (username === tempUsername && !siteIsShn) {
        const action = this.$store.state.authOpen ? 'setAuthStep' : 'openAuthDialog';
        this.$store.dispatch(action, { authStep: this.nextStepForNewUser });
      } else if (redirectToInfluitive) {
        window.location = redirect;
      } else if (this.redirectedFromModeration) {
        window.location = `${window.location.origin}/moderation`;
      } else {
        if (this.authDialogRedirect) {
          this.$store.dispatch('closeAuthDialog');
          this.$router.push(this.authDialogRedirect);
          this.$store.dispatch('clearAuthDialogRedirect');
          return;
        }

        this.$router.push(this.$route.query.redirect || { name: 'home' });

        this.$store.dispatch('addToastNotification', {
          toastType: 'success',
          description: `Welcome back ${username}!`,
        });

        const userHasNeverBeenLoggedInBeforeThis = !this.$store.getters.currentUser.lastLoginAt;
        if (siteIsShn) {
          if (userHasNeverBeenLoggedInBeforeThis) {
            this.$store.dispatch('setFirstTimeLoggingIntoSHN', true);
            this.$store.dispatch('openAuthDialog', { authStep: 'setUsername' });
            return;
          }

          if (!this.currentUser.hasDisorder) {
            this.$store.dispatch('openAuthDialog', { authStep: 'requestDisorderDemographicInfo' });
            return;
          }

          if (!this.currentUser.hasSocialSite) {
            this.$store.dispatch('openAuthDialog', { authStep: 'setSocialSites' });
            return;
          }
        }

        this.$store.dispatch('closeAuthDialog');
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_social-login';
</style>

<docs>

Buttons for social login / register with Google and Facebook

</docs>
