export default {
  data() {
    return {
      otTimeoutId: null,
    };
  },
  methods: {
    setDataLayer(dataLayer) {
      const dataLayerObject = typeof dataLayer === 'string' ? JSON.parse(dataLayer) : (dataLayer || {});
      const {
        visitorType,
        currentUser,
        pageViewId,
        userIsLoggedIn,
        userHasSubscribed,
        userHasBeenLoggedIn,
        userWasReferredByEmail,
      } = this.$store.getters;

      const payload = {
        ...dataLayerObject,
        ...{ visitorType },
        ...{ pageViewId },
        ...{ userIsLoggedIn },
      };

      if (currentUser) {
        payload.nexus_user_id = currentUser.id;
        payload.wp_user_id = currentUser.legacyId;
        payload.vmp_email = currentUser.sha256Email;
      }

      if (window && window.Krux && window.Krux.user) {
        payload.krux_user_id = window.Krux.user;
      }

      const snowplowUID = this.$snowplow.getSnowplowDuid();
      if (snowplowUID) {
        payload.snowplow_user_d_id = snowplowUID;
      }

      if (
        userHasBeenLoggedIn
        || userWasReferredByEmail
        || userHasSubscribed
      ) {
        payload.is_1p_identifiable = true;
      }

      payload.ga_user_id = this.gaUserId();

      this.$store.dispatch('setDataLayer', payload);
    },

    gaUserId() {
      const fromWindow = window && window.ga && window.ga.getAll && window.ga.getAll()[0].get('clientId');
      return fromWindow || this.gaUserIdFromCookie();
    },

    gaUserIdFromCookie() {
      const cookie = ((document && document.cookie) || '').match(/_ga=(.*?);/);
      return cookie && cookie[1];
    },

    // Pushes data layer out to 3rd party services
    pushDataLayer(datalayer) {
      const dataLayer = datalayer || this.$store.getters.dataLayer;
      this.$gdfp.setTargeting(dataLayer);
      this.pushDatalayerToGtm(dataLayer);
    },
    pushDatalayerToGtm(payload) {
      if (!window || !window.dataLayer) {
        return;
      }
      window.dataLayer.push(payload);
    },
    sendNexusReady() {
      if (this.shouldWaitForOneTrust()) {
        this.$gtm.trackEvent({ event: 'ot-not-ready' });
        window.addEventListener('OneTrustGroupsUpdated', this.sendReadyAndContentView);
        // if OT doesn't load within 6s, fire event anyways
        this.otTimeoutId = window.setTimeout(this.otTimeout, 6000);
      } else {
        this.$gtm.trackEvent({ event: 'nexus-ready' });
        this.sendContentView();
      }
    },
    otTimeout() {
      this.$gtm.trackEvent({ event: 'ot-timeout' });
      this.sendReadyAndContentView();
    },
    sendReadyAndContentView() {
      window.clearTimeout(this.otTimeoutId);
      window.removeEventListener('OneTrustGroupsUpdated', this.sendReadyAndContentView);
      window.setTimeout(() => {
        this.$gtm.trackEvent({ event: 'nexus-ready' });
        this.sendContentView();
      });
    },
    sendContentView() {
      const { name } = this.$route;
      const fullUrl = this.$route.fullPath;
      const additionalEventData = {};
      this.$gtm.trackView(name, fullUrl, additionalEventData);
    },
    shouldWaitForOneTrust() {
      return this.$site.settings.one_trust_id && !window.oneTrustFired;
    },
    setTrackingInAC(email) {
      if (typeof (window.vgo) !== 'undefined') {
        window.vgo('setEmail', email);
        window.vgo('process');
      }
    },
  },
};
