<template>
  <div class="account-confirmation">
    <div v-if="!siteHasFeature('social_network')" class="account-confirmation__container">
      <router-link
        class="account-confirmation__cta"
        :to="ctaLink"
        @click.native="handleClickingCTA"
      >
        <img
          src="/common/nexus/bubbles.png"
          alt=""
          class="account-confirmation__cta-image"
          loading="lazy"
        >
        <div class="account-confirmation__cta-text">
          {{ ctaText }}
        </div>
      </router-link>
      <router-link
        v-if="!siteHasFeature('mental_health')"
        class="account-confirmation__cta"
        :to="{ name: 'community' }"
        @click.native="handleClickingCTA"
      >
        <img
          src="/common/nexus/megaphone.png"
          alt=""
          class="account-confirmation__cta-image"
          loading="lazy"
        >
        <div class="account-confirmation__cta-text">
          See what's new in the community
        </div>
      </router-link>
      <router-link
        class="account-confirmation__cta"
        :to="{ name: 'userProfileOverview' }"
        @click.native="handleClickingCTA"
      >
        <img
          src="/common/nexus/pencil.png"
          alt=""
          class="account-confirmation__cta-image"
          loading="lazy"
        >
        <div class="account-confirmation__cta-text">
          Set up your profile
        </div>
      </router-link>
    </div>
    <div v-else class="account-confirmation__container account-confirmation__container--shn">
      <router-link
        class="account-confirmation__cta account-confirmation__cta--shn"
        :to="{ name: 'userProfileOverview' }"
        @click.native="handleClickingCTA"
      >
        <img
          src="/common/nexus/profile-emblem.png"
          alt=""
          class="account-confirmation__cta-image account-confirmation__cta-image--shn"
          loading="lazy"
        >
        <div class="account-confirmation__cta-text account-confirmation__cta-text--shn">
          Set up your profile
        </div>
      </router-link>
      <a
        :href="dashboardURL"
        class="account-confirmation__cta"
      >
        <img
          src="/common/nexus/resources-emblem.png"
          alt=""
          class="account-confirmation__cta-image account-confirmation__cta-image--shn"
          loading="lazy"
        >
        <div class="account-confirmation__cta-text account-confirmation__cta-text--shn">
          Check out Health Leader resources
        </div>
      </a>
      <a
        :href="`${dashboardURL}/channels/46`"
        class="account-confirmation__cta"
      >
        <img
          src="/common/nexus/patient-leader-certification.png"
          alt=""
          class="account-confirmation__cta-image account-confirmation__cta-image--shn"
          loading="lazy"
        >
        <div class="account-confirmation__cta-text account-confirmation__cta-text--shn">
          Complete Patient Leader Certification
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AccountConfirmation',
  data() {
    return {
      dashboardURL: '/api/dashboard-redirect',
    };
  },
  computed: {
    ...mapGetters(['siteHasFeature', 'authDialogRedirect']),
    ctaText() {
      return this.siteHasFeature('mental_health') ? 'Read recent articles' : 'Visit the forums';
    },
    ctaLink() {
      return this.siteHasFeature('mental_health') ? '/page/1' : { name: 'forums' };
    },
  },
  mounted() {
    const redirect = this.$route?.query?.redirect || this.authDialogRedirect;
    const redirectToInfluitive = /^\/?api\/dashboard-redirect/.test(redirect);

    if (redirectToInfluitive) {
      window.location = redirect;
      this.$store.dispatch('closeAuthDialog');
    } else if (redirect) {
      this.$router.push(redirect);
    }
  },
  methods: {
    handleClickingCTA() {
      this.$store.dispatch('closeAuthDialog');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_account-confirmation';
</style>
