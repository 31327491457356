<template>
  <div class="set-username">
    <form-component
      method="post"
      @submit.prevent="submitUsernameForm"
    >
      <username-input
        ref="username"
        v-model="username"
        class="set-username__input"
      >
        <template v-slot:input>
          Username
        </template>
      </username-input>
      <loading-button
        input-type="submit"
        :loading="tryingToRegister"
        button-type="registration"
        class="set-username__submit-button"
      >
        {{ buttonText }}
      </loading-button>
    </form-component>
    <progress-indicator :current-step="currentStepBasedOnSiteFeature" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UsernameInput from '@/components/Auth/UsernameInput.vue';
import LoadingButton from '@/components/Global/LoadingButton.vue';
import ProgressIndicator from '@/components/Auth/ProgressIndicator.vue';
import { SET_CURRENT_USER } from '@/graphql/mutations/current-user-mutation';

export default {
  name: 'SetUsername',
  components: {
    UsernameInput,
    LoadingButton,
    ProgressIndicator,
  },
  data() {
    return {
      username: '',
      tryingToRegister: false,
    };
  },
  validations: {},
  computed: {
    ...mapGetters([
      'siteHasFeature',
      'firstTimeLoggingIntoSHN',
      'userIsUnverified',
      'currentUser',
    ]),
    currentStepBasedOnSiteFeature() {
      if (this.siteHasFeature('social_network')) {
        return '2';
      }
      return '3';
    },
    buttonText() {
      if (this.siteHasFeature('social_network')) {
        return 'Continue';
      }
      return 'Sign up';
    },
  },
  methods: {
    moveToNextStep() {
      let nextStep = 'accountConfirmation';

      if (this.firstTimeLoggingIntoSHN && !this.userIsUnverified) {
        nextStep = 'accountConfirmation';
      } else if (this.siteHasFeature('social_network')) {
        nextStep = 'requestDisorderDemographicInfo';
      }

      this.$store.dispatch('setAuthStep', { authStep: nextStep });
    },
    submitUsernameForm() {
      this.tryingToRegister = true;

      this.$v.$touch();
      this.$refs.username.checkErrors();
      if (this.$refs.username.$v.$invalid) {
        this.tryingToRegister = false;
        return;
      }

      // If a user isn't logged in, and there's an email query, the user has a 'lead' account
      // and tried to login, register, or reset password.
      // They need to choose a username then verify their email to upgrade from lead to member.
      if (!this.$store.state.currentUser.loggedIn && this.$route.query.email) {
        this.upgradeAccount();
      } if (
        this.siteHasFeature('social_network')
        && this.username === this.currentUser?.username
      ) {
        this.moveToNextStep();
      } else {
        this.setUsername();
      }
    },
    setUsername() {
      this.$apollo.mutate({
        mutation: SET_CURRENT_USER,
        variables: {
          username: this.username,
        },
      }).then(({ data }) => {
        this.$store.dispatch('setCurrentUser', data.updateUser);
        this.moveToNextStep();
      }).catch((error) => {
        if (error.message === 'GraphQL error: Username already exists') {
          this.$store.dispatch('addToastNotification', {
            toastType: 'error',
            description: 'This username already exists. Please try another username.',
          });

          return;
        }
        this.$store.dispatch('addGenericErrorNotification');
      }).finally(() => {
        this.tryingToRegister = false;
      });
    },
    upgradeAccount() {
      this.$auth.upgradeLead({
        username: this.username,
        email: this.$route.query.email,
      }).then((response) => {
        if (response.username_exists) {
          this.$store.dispatch('addToastNotification', {
            toastType: 'error',
            description: 'This username already exists. Please try another username.',
          });
          return;
        }
        if (response.status === 'success') {
          this.$store.dispatch('setAuthStep', { authStep: 'accountConfirmation' });
        } else {
          this.$store.dispatch('addGenericErrorNotification');
        }
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      }).finally(() => {
        this.tryingToRegister = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_set-username';
</style>

<docs>
Component to serve as a step in the registration process. Holds the username form.

</docs>
