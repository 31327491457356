<template>
  <header class="header">
    <site-logo link />
    <menu-button />
    <client-only>
      <navigation-drawer
        v-show="$store.state.navigation.open"
        :focus-ref="navFocusRef"
      />
    </client-only>
    <search-button @search-focus="navFocusRef = 'search'" />
    <accessibility-button @a11yButtonClicked="a11yMenuFocus = true" />
    <lazy-hydrate when-visible>
      <accessibility-menu
        v-if="$store.state.accessibility.menuOpen"
        :set-focus="a11yMenuFocus"
      />
    </lazy-hydrate>
    <profile-button v-if="$store.getters.userIsLoggedIn" />
    <login-button v-else-if="this.$store.getters.siteHasFeature('Accounts')" />
    <lazy-hydrate on-interaction="click">
      <profile-menu v-if="$store.state.profileMenuOpen" />
    </lazy-hydrate>
    <subscribe-trigger v-if="displaySubscribeButton" />
    <one-tap
      v-if="displayOneTap"
    />
    <facebook-one-tap v-if="displayFacebookOneTap" />
  </header>
</template>

<script>
import SiteLogo from '@/components/Global/SiteLogo.vue';
import MenuButton from '@/components/Global/MenuButton.vue';
import SearchButton from '@/components/Global/SearchButton.vue';
import AccessibilityButton from '@/components/Accessibility/AccessibilityButton.vue';
import LoginButton from '@/components/Global/LoginButton.vue';
import ProfileButton from '@/components/User/ProfileButton.vue';
import NavigationDrawer from '@/components/NavigationDrawer/NavigationDrawer.vue';
import AccessibilityMenu from '@/components/Accessibility/AccessibilityMenu.vue';
import ProfileMenu from '@/components/User/ProfileMenu.vue';
import OneTap from '@/components/Header/OneTap.vue';
import FacebookOneTap from '@/components/Header/FacebookOneTap.vue';
import SubscribeTrigger from '@/components/Header/SubscribeTrigger.vue';
import { mapGetters } from 'vuex';
import { isFacebookApp, isFeatureFlagEnabled } from '@/utils/utils';

export default {
  name: 'TheHeader',
  components: {
    SiteLogo,
    MenuButton,
    SearchButton,
    AccessibilityButton,
    LoginButton,
    ProfileButton,
    OneTap,
    NavigationDrawer,
    AccessibilityMenu,
    ProfileMenu,
    FacebookOneTap,
    SubscribeTrigger,
  },
  data() {
    return {
      navFocusRef: null,
      a11yMenuFocus: false,
    };
  },
  computed: {
    ...mapGetters([
      'userWasReferredByEmail',
      'userIsLoggedIn',
      'userHasSubscribed',
    ]),
    displayOneTap() {
      return this.$site.settings.one_tap_enabled
        && this.$site.settings.google_client_id
        && !this.userIsLoggedIn
        && !this.$store.state.subscriptionWallIsActive
        && !isFacebookApp(this.$store.state.userAgent);
    },
    displayFacebookOneTap() {
      return isFacebookApp(this.$store.state.userAgent) && isFeatureFlagEnabled(this.$site, 'facebook_one_tap');
    },
    displaySubscribeButton() {
      return isFeatureFlagEnabled(this.$site, 'enable_subscription_button_in_header')
        && !this.userIsLoggedIn
        && !this.userWasReferredByEmail
        && !this.userHasSubscribed;
    },
  },
};
</script>

<style lang="scss"> // no scoped - to share classes with child components
  @import '@/stylesheets/components/_header';
</style>
