<template>
  <form-component
    ref="user-social-form"
    class="user-social-form"
    @submit.prevent="handleSubmit"
  >
    <fieldset
      v-for="(site, i) in socialSites"
      :key="`socialFieldset${i}`"
      class="user-social-form__fieldset"
    >
      <legend class="visually-hidden">Please provide a social media platform and handle.</legend>
      <validation-error
        v-if="i === 0"
        ref="validationError"
        class="validation-error--label"
        :model="`${ socialSites.some((h) => h.handle && h.platform) }`"
        :validator="$v.socialSites"
      />
      <label class="user-social-form__platform">
        <div class="user-social-form__label-text">Platform</div>
        <select-input
          :id="`platform-select-${i}`"
          v-model="socialSites[i].platform"
          :options="optionsWithSelection(site)"
          class="user-social-form__select-input select-input--cyan"
          @input="checkForDupes"
        />
      </label>
      <label class="user-social-form__handle">
        <div class="user-social-form__label-text">{{ labelForSite(site) }}</div>
        <text-input
          :id="`handle-input-${i}`"
          v-model="socialSites[i].handle"
          :value="site.handle"
          name="site-url"
          input-type="text"
          :inline-prefix="handlePrefix[socialSites[i].platform]"
          include-icon
          class="user-social-form__text-input text-input--full-width"
        />
      </label>
    </fieldset>
    <p v-if="$slots.disclaimer" class="user-social-form__disclaimer"><slot name="disclaimer" /></p>
    <button
      v-if="allowMultiple"
      type="button"
      class="user-social-form__button user-social-form__button--add button"
      @click="addInput"
    >+ Add</button>
    <loading-button
      class="user-social-form__button button--large"
      type="submit"
      :loading="tryingToSubmit"
    >{{ saveButtonText }}</loading-button>
  </form-component>
</template>

<script>
import TextInput from '@/components/Inputs/TextInput.vue';
import SelectInput from '@/components/Inputs/SelectInput.vue';
import LoadingButton from '@/components/Global/LoadingButton.vue';
import ValidationError from '@/components/Global/ValidationError.vue';
import { SET_SOCIAL_SITES } from '@/graphql/mutations/user-mutations';

export default {
  name: 'UserSocialForm',
  components: {
    TextInput,
    SelectInput,
    LoadingButton,
    ValidationError,
  },
  props: {
    sites: {
      type: Array,
      default: () => ([{
        id: '',
        type: '',
        url: '',
      }]),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    saveButtonText: {
      type: String,
      default: 'Save',
    },
    allowMultiple: {
      type: Boolean,
      default: true,
    },
    savedSitesToValidateAgainst: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tryingToSubmit: null,
      socialSites: [
        { platform: null, handle: null },
      ],
      platforms: [
        { value: '', name: 'Select' },
        {
          value: 'WEBSITE', name: 'Website', label: 'Link',
        },
        {
          value: 'BLOG', name: 'Blog', label: 'Link',
        },
        {
          value: 'FACEBOOK', name: 'Facebook', label: 'Handle',
        },
        {
          value: 'TWITTER', name: 'Twitter', label: 'Handle',
        },
        {
          value: 'YOUTUBE', name: 'Youtube', label: 'Handle',
        },
        {
          value: 'PINTEREST', name: 'Pinterest', label: 'Handle',
        },
        {
          value: 'INSTAGRAM', name: 'Instagram', label: 'Handle',
        },
        {
          value: 'LINKEDIN', name: 'LinkedIn', label: 'Link',
        },
        {
          value: 'TIKTOK', name: 'TikTok', label: 'Handle',
        },
        {
          value: 'PODCAST', name: 'Podcast', label: 'Link',
        },
      ],
      handlePrefix: {
        WEBSITE: 'https://',
        BLOG: 'https://',
        FACEBOOK: '@',
        TWITTER: '@',
        YOUTUBE: '@',
        PINTEREST: '@',
        INSTAGRAM: '@',
        LINKEDIN: 'linkedin.com/in/',
        TIKTOK: '@',
        PODCAST: 'https://',
      },
    };
  },
  validations: {
    socialSites: {
      socialSites: (sites) => sites.some((h) => h.handle && h.platform),
      noDuplicateSocials: (sites, $v) => {
        const sitesToValidate = [...sites, ...$v.formatSiteList($v.savedSitesToValidateAgainst)];
        const flatList = sitesToValidate.map((s) => `${s.platform}${s.handle}`);
        const dupes = flatList.filter((s, index) => flatList.indexOf(s) !== index);
        return dupes.length === 0;
      },
      twitterHandle: (sites) => {
        const twitterPlatforms = sites.filter((s) => s.platform === 'TWITTER');
        const handlesMeetRules = twitterPlatforms.every((s) => s.handle !== null && s.handle.length > 4 && s.handle.length < 16);
        return handlesMeetRules;
      },
      instagramHandle: (sites) => {
        const instagramPlatforms = sites.filter((s) => s.platform === 'INSTAGRAM');
        const handlesMeetRules = instagramPlatforms.every((s) => s.handle !== null && s.handle.length < 31);
        return handlesMeetRules;
      },
      url: (sites) => {
        const urlPlatforms = sites.filter((s) => s.platform === 'WEBSITE' || s.platform === 'BLOG' || s.platform === 'PODCAST');
        const handlesMeetRules = urlPlatforms.every((s) => s.handle !== null && s.handle.includes('.'));
        return handlesMeetRules;
      },
    },
  },
  created() {
    this.socialSites = this.formatSiteList(this.sites) || { platform: null, handle: null };
  },
  mounted() {
    document.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    checkForDupes() {
      this.$v.$touch();
      this.$refs.validationError[0].checkErrors();
    },
    formatSiteList(siteList) {
      if (!siteList || !siteList.length) return [];
      return siteList.map((site) => ({ platform: site.type.toUpperCase(), handle: site.url, id: site.id }));
    },
    optionsWithSelection(site) {
      const platforms = this.platforms.map((p) => ({ ...p }));

      if (!site.platform) return platforms;

      const i = platforms.findIndex((p) => p.value === site.platform);
      if (i >= 0) platforms[i].selected = true;

      return platforms;
    },
    labelForSite(site) {
      return this.platforms.find((p) => p.value === site.type?.toUpperCase)?.label || 'Handle';
    },
    addInput() {
      this.socialSites.push({ platform: null, handle: null });
    },
    onClickOutside(event) {
      if (!this.visible) {
        this.visible = true;
        return;
      }
      if (this.$el.contains(event.target)) { return; }
      this.$emit('focusout', this.$el, event);
    },
    handleSubmit() {
      this.tryingToSubmit = true;
      const filledOutSocialSites = this.socialSites.filter((s) => s.platform && s.handle);
      const formattedSocialSites = filledOutSocialSites.map((s) => {
        const formattedSite = {
          handle: s.handle,
          platform: s.platform,
        };

        if (s.id) formattedSite.id = s.id;

        return formattedSite;
      });

      const nothingHasChanged = this.socialSites[0].handle === this.sites[0].url
      && this.socialSites[0].id === this.sites[0].id
      && this.socialSites[0].platform.toLowerCase() === this.sites[0].type;

      if (this.isEditing && nothingHasChanged) {
        this.$emit('success');
        return;
      }

      this.$refs.validationError[0].checkErrors();

      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }

      this.$apollo.mutate({
        mutation: SET_SOCIAL_SITES,
        variables: {
          socialSites: formattedSocialSites,
        },
      }).then(() => {
        this.$emit('success');
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      }).finally(() => {
        this.tryingToSubmit = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_user-social-form';
</style>
