<template>
  <div
    class="layout layout--sidebar"
    :class="{
      'layout--subscription-required': hideContentBehindSubscriptionWall,
    }"
  >
    <div v-if="showLeaderboardAd" class="layout__leaderboard-container">
      <ad-slot mapping="leaderboard" />
    </div>
    <slot name="hero" />
    <div class="layout__flex-at-wide">
      <div id="layout-content" class="layout__content" data-test-id="sidebar-layout-content">
        <not-found v-if="is404" />
        <slot v-else />
      </div>
      <div
        class="layout__gdfp-sidebar"
        data-test-id="sidebar-layout-gdfp"
      >
        <slot name="sidebar-ads" />
        <adblock-prompt />
      </div>
    </div>
  </div>
</template>

<script>
import AdSlot from '@/components/Ads/AdSlot.vue';
import AdblockPrompt from '@/components/Ads/AdblockPrompt.vue';
import NotFound from '@/components/Global/NotFound.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarLayout',
  components: {
    AdSlot,
    AdblockPrompt,
    NotFound,
  },
  props: {
    showLeaderboardAd: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters([
      'is404',
      'subscriptionWallIsActive',
    ]),
    hideContentBehindSubscriptionWall() {
      const qualifyingPostTypes = [
        'post',
        'page',
      ];

      return qualifyingPostTypes.includes(this.post?.type) && this.subscriptionWallIsActive;
    },
  },
};
</script>
