<script>
import kebabCase from '@/utils/kebab-case';
import AuthWallLoginPrompt from '@/components/Auth/AuthWallLoginPrompt.vue';
import SubscriptionWall from '@/components/Subscription/SubscriptionWall.vue';
import contentSections from './Sections/index';

export default {
  name: 'ContentRenderer',
  components: {
    ...contentSections.sectionComponents,
    AuthWallLoginPrompt,
    SubscriptionWall,
  },
  props: {
    sections: {
      type: Array,
      required: true,
    },
    featuredImageSize: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    createElement: () => {},
  }),
  computed: {
    contrastSettingIsRegular() {
      if (this.$store.state?.accessibility?.settings?.contrast === 'contrast-reg') return true;

      return false;
    },
  },
  methods: {
    isContentSection(sectionType) {
      return Object.keys(contentSections.sectionTypesMap).includes(sectionType);
    },
    sectionTypeOrPost(sectionType) {
      if (this.isContentSection(sectionType)) {
        return contentSections.sectionTypesMap[sectionType];
      }
      return 'PostTeaser';
    },
    formatContentOptions(section) {
      if (this.isContentSection(section.type)) {
        // This must return a new object, otherwise section.options is mutated and will be missing elsewhere in the app!
        return { ...{}, ...section.options };
      }
      return {
        post: section,
        featuredImageSize: this.featuredImageSize,
      };
    },
    /**
     *  This method returns a dynamic component that is equivalent to this template
     *  <component
     *    :is="this.sectionTypeOrPost(section.type)"
     *    v-bind="formatContentOptions(section)"
     *  />
    */
    dynamicSectionComponent(section) {
      const component = this.createElement(
        this.sectionTypeOrPost(section.type),
        { attrs: this.formatContentOptions(section) },
      );
      return component;
    },
    createSectionElements(sections) {
      if (!sections) return null;

      return sections.map((section) => {
        // Fail silently if the section.type does not have a component mapping
        if (!section.type || !this.sectionTypeOrPost(section.type)) { return false; }

        const sectionComponent = this.dynamicSectionComponent(section);
        const sectionClasses = [
          'content__container',
          `content__container--${kebabCase(section.type)}`,
        ];
        const pageTypesThatNeedModifiers = [
          'home',
        ];

        if (pageTypesThatNeedModifiers.includes(this.$route.name)) {
          sectionClasses.push(`content__container--${kebabCase(this.$route.name)}`);
        }

        const containerProps = {
          attrs: {
            class: sectionClasses.join(' '),
          },
        };

        if (section.sectionBackgroundColor && this.contrastSettingIsRegular) {
          containerProps.style = {
            'background-color': section.sectionBackgroundColor,
          };
        }

        return this.createElement('div', containerProps, [sectionComponent]);
      });
    },
  },
  render(createElement) {
    if (!this.sections) {
      this.$logger.error('No sections in ContentRenderer');
      return '';
    }

    this.createElement = createElement;

    let sectionElements;

    let authSections = this.sections.filter((s) => s.isAuthwalled);
    const renderAuthWall = !this.$site.settings.subscription_wall
      && this.$site.settings.enable_auth_wall
      && authSections.length > 0
      && !this.$store.state.currentUser.loggedIn;

    const renderSubscriptionWall = authSections.length > 0 && this.$store.state.subscriptionWallIsActive;

    if (renderSubscriptionWall) {
      const freeSections = this.sections.filter((s) => !s.isAuthwalled);
      const freeSectionElements = this.createElement('div', { class: 'content__free', 'data-test-id': 'content-free-section' }, this.createSectionElements(freeSections));
      const subscriptionWall = this.createElement('SubscriptionWall');
      const authSectionContent = this.createElement('div', { class: 'content__subscription-required' }, this.createSectionElements(authSections));
      const authSectionElements = this.createElement('div', { class: 'content__auth' }, [subscriptionWall, authSectionContent]);

      sectionElements = [freeSectionElements, authSectionElements];
    } else if (renderAuthWall) {
      const freeSections = this.sections.filter((s) => !s.isAuthwalled);
      const freeSectionElements = this.createElement('div', { class: 'content__free', 'data-test-id': 'content-free-section' }, this.createSectionElements(freeSections));

      const recommendationSection = authSections.filter((s) => s.type === 'Recommendation');

      let recommendationElements = [];

      if (recommendationSection.length > 0) {
        authSections = authSections.filter((s) => s !== recommendationSection[0]);
        recommendationElements = this.createSectionElements([recommendationSection[0]]);
      }

      const loginPrompt = this.createElement('AuthWallLoginPrompt');
      const authSectionContent = this.createElement('div', { class: 'content__auth-content-mask' }, this.createSectionElements(authSections));
      const authSectionElements = this.createElement('div', { class: 'content__auth' }, [loginPrompt, authSectionContent]);

      sectionElements = [freeSectionElements, authSectionElements, recommendationElements];
    } else {
      sectionElements = this.createSectionElements(this.sections);
    }

    return this.createElement('div', { class: 'content' }, sectionElements);
  },
};
</script>

<docs>

This is the main component for rendering content from the nexus API.
The ContentRenderer receives an array of content sections and generates the
necessary content recursively and dynamically. For an example of the content
array, see the content-mock.js file used to create the example below.

</docs>
