<template>
  <button
    class="follow-button button"
    :class="{ 'button--secondary' : isFollowing }"
    @click="tryToFollow"
  >
    <icon-component
      v-if="isInProfileTooltipOrMemberProfile"
      :name="followIconName"
      :title="followIconTitle"
      class="follow-button__follow-icon button__icon"
      :class="{ 'follow-button__follow-icon--following' : isFollowing }"
    />
    <span
      class="follow-button__text"
      :class="{ 'follow-button__text--edit' : editToUnfollow }"
    >
      {{ followButtonText }}
    </span>
    <icon-component
      v-if="editToUnfollow"
      title="Unfollow"
      name="x"
      class="follow-button__unfollow-icon"
    />
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_FOLLOWING_USER } from '@/graphql/mutations/following-mutations';

export default {
  name: 'FollowButton',
  props: {
    currentlyFollowing: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
    isOnFollowingDetails: {
      type: Boolean,
      default: false,
    },
    isInProfileTooltipOrMemberProfile: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFollowing: false,
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'currentUser',
      'userIsUnverified',
    ]),
    editToUnfollow() {
      return this.isOnFollowingDetails && this.edit && this.isFollowing;
    },
    followButtonText() {
      if (this.isFollowing && this.isOnFollowingDetails) {
        return this.text;
      }
      if (this.isFollowing && this.isInProfileTooltipOrMemberProfile) {
        return 'Following';
      }
      if (this.isFollowing) {
        return 'Unfollow';
      }
      return 'Follow';
    },
    followIconName() {
      return this.isFollowing ? 'check-outlined' : 'follow';
    },
    followIconTitle() {
      return this.isFollowing ? 'check-outlined icon' : 'follow icon';
    },
    toastNotificationDescriptionText() {
      if (this.isFollowing) {
        return `You're now following ${this.user.username}`;
      }
      return `You are no longer following ${this.user.username}`;
    },
  },
  created() {
    this.isFollowing = this.currentlyFollowing;
  },
  methods: {
    tryToFollow(event) {
      this.$emit('click');
      if (!this.userIsLoggedIn) {
        this.$store.dispatch('openRegisterDialog', { dialogText: 'Create an account to follow this member.' });
        return;
      }

      if (this.user.id === this.currentUser.id) {
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: 'You cannot follow yourself',
          type: 'Following',
        });
        return;
      }

      // we don't want to do anything until the user edits
      if (this.isOnFollowingDetails && !this.edit && this.isFollowing) {
        return;
      }

      this.$snowplow.trackButtonEvent({
        data: {
          type: `${this.isFollowing ? 'unfollow user' : 'follow user'}`,
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });

      this.handleFollowing();
    },
    handleFollowing() {
      this.$apollo.mutate({
        mutation: UPDATE_FOLLOWING_USER,
        variables: {
          isFollowing: !this.isFollowing,
          userId: this.user.id,
        },
        update(cache) {
          // When a user follows or unfollows there can be many associated cached queries
          // that would need to be updated, each in a slightly different way.
          // rather than deal with all the crazy logic for that, just delete any relevant cache
          // entries and let the network handle subsequent queries
          const followCacheKeys = Object.keys(cache.data.data.ROOT_QUERY).filter((key) => /^userFollowing/.test(key));

          if (!followCacheKeys.length) { return; }

          followCacheKeys.forEach((key) => {
            // eslint-disable-next-line no-param-reassign
            delete cache.data.data.ROOT_QUERY[key];
          });
        },
      }).then(() => {
        this.isFollowing = !this.isFollowing;
        this.$store.dispatch('addToastNotification', {
          toastType: 'success',
          description: `${this.toastNotificationDescriptionText}`,
          type: 'Following',
        });
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_follow-button';
</style>
