/**
 * Snowplow Vue plugin
 *
 * This plugin handles the following:
 * - Initializing the snowplow tracker
 * - Page view tracking with vue-router
 * - Adding an API to the vue instance for tracking events
 * - Grabbing the snowplow domain id
 */

import { Tracking, initUserTracking } from '@/utils/tracking';
import throttle from 'lodash/throttle';

const setReferrer = () => {
  /**
    * Some necessary redirects lead to incorrect referrer values
    * in Snowplow events. Snowplow provides a method for setting
    * the referrer for all following events ongoing. This method
    * manages the referrer on a per event basis. See
    * mantainReferrerForSpaNavigation in navigationGuards.js for
    * window.referrer's definition.
    */

  const referrer = window.referrer || document.referrer;
  window.snowplow_hu('setReferrerUrl', referrer);
};

const api = (store) => ({
  /**
   * Function to extract the Snowplow Domain User ID from the first-party cookie set by the Snowplow JavaScript Tracker
   *
   * @param string cookieName (optional) The value used for "cookieName" in the tracker constructor argmap
   * (leave blank if you did not set a custom cookie name)
   *
   * @return string or bool The ID string if the cookie exists or false if the cookie has not been set yet
   */
  getSnowplowDuid(cookieName) {
    const myCookieName = cookieName || '_sp_';
    const matcher = new RegExp(`${myCookieName}id\\.[a-f0-9]+=([^;]+);?`);
    const match = document.cookie.match(matcher);
    if (match && match[1]) {
      return match[1].split('.')[0];
    }
    return false;
  },

  /**
   * Track a structured event in snowplow
   *
   * Example usage:
   * this.$snowplow.track('search', 'click', {
   *   label: 'toggle search',
   * });
   *
   * Available options: label, property, value, context
   *
   * @param action String
   * @param {category, action, options} data Object
   */
  track(category, action, options = {}) {
    Tracking.event(category, action, options);
  },

  /**
   * Track a self describing (unstructured) event in snowplow
   * eventJson must include a schema and data object.
   *
   * Example usage:
   *
   * this.$snowplow.trackSelfDescribingEvent({
   *   schema: 'iglu:com.acme_company/viewed_product/jsonschema/2-0-0',
   *   data: {
   *       productId: 'ASO01043',
   *       category: 'Dresses',
   *       brand: 'ACME',
   *       returning: true,
   *       price: 49.95,
   *       sizes: ['xs', 's', 'l', 'xl', 'xxl'],
   *       availableSince: new Date(2020,3,7)
   *   }
   * });
   *
   * @param { schema, data } eventJson
   */
  trackSelfDescribingEvent(eventJson, context) {
    Tracking.selfDescribingEvent(eventJson, context);
  },

  /**
   * Track button event - requirement 8.5
   * Fired when a user clicks a button on the site that is not tracked with link click tracking
   * Intrinsically related to changes to the sourceContext, this event also updates
   * the source context
   * @param {Object} payload
   * {
   *   button_type: {String},
   *   button_text: {String},
   *   button_class_list: {Array},
   *   button_id: {String},
   * }
   */
  trackButtonEvent(payload) {
    // If a store was provided, update the sourceContext
    if (store) {
      store.dispatch('updateSourceContext', {
        trigger: payload.data,
      });
    }

    // Renaming keys because we need them to be these specific keys
    // for button tracking (in tracking.js), but with the way they are set in the cmps
    // for updateSourceContext in snowplow-contexts.js
    const newKeys = {
      type: 'button_type',
      text: 'button_text',
      classes: 'button_class_list',
      id: 'button_id',
    };

    const data = Object.keys(payload.data).reduce((acc, key) => {
      if (newKeys[key] === undefined) {
        return acc;
      }

      acc[newKeys[key]] = payload.data[key];
      return acc;
    }, {});

    const trackButtonContext = [
      store.getters.complianceContext,
      store.getters.globalContext,
      store.getters.userContext,
    ];

    const trackButtonData = {
      schema: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_button_tag/jsonschema/1-0-0',
      data,
    };

    this.trackSelfDescribingEvent(trackButtonData, trackButtonContext);
  },

  trackToastNotification(payload) {
    const toastNotificationContexts = [
      store.getters.complianceContext,
      store.getters.globalContext,
      store.getters.userContext,
      store.getters.sourceContext,
    ];

    const toastNotificationData = {
      schema: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_toast_tag/jsonschema/1-0-0',
      data: payload.data,
    };

    this.trackSelfDescribingEvent(toastNotificationData, toastNotificationContexts);
  },

  trackExperimentBucket(experimentSymbol, bucketKey) {
    const contexts = [
      store.getters.complianceContext,
      store.getters.globalContext,
      store.getters.userContext,
      store.getters.sourceContext,
    ];

    const data = {
      schema: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_ab_test_new_session_event/jsonschema/1-0-0',
      data: { session_id: store.getters.currentUser.sessionId, testing_bucket: experimentSymbol, testing_bucket_parameters: bucketKey },
    };

    this.trackSelfDescribingEvent(data, contexts);
  },

  trackRecaptcha(payload) {
    const recaptchaContexts = [
      store.getters.complianceContext,
      store.getters.globalContext,
      store.getters.userContext,
    ];

    const reacaptchaNotificationData = {
      schema: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_recaptcha_tag/jsonschema/1-0-0',
      data: payload.data,
    };

    this.trackSelfDescribingEvent(reacaptchaNotificationData, recaptchaContexts);
  },

  connectSocialAccountTracking(payload) {
    const socialConnectContext = [
      store.getters.complianceContext,
      store.getters.globalContext,
      store.getters.userContext,
    ];

    const socialConnectData = {
      schema: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_social_connect_tag/jsonschema/1-0-0',
      data: payload.data,
    };

    this.trackSelfDescribingEvent(socialConnectData, socialConnectContext);
  },

  enableScrollTracking(payload) {
    if (!payload.data) {
      return;
    }

    const scrollTrackingContexts = [
      store.getters.complianceContext,
      store.getters.globalContext,
      store.getters.userContext,
      store.getters.a11yContext,
    ];

    const scrollTrackingData = {
      schema: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_scroll_tracking_tag/jsonschema/1-0-0',
      data: payload.data,
    };

    this.trackSelfDescribingEvent(scrollTrackingData, scrollTrackingContexts);
  },

  enableReactionTracking(payload) {
    const reactionTrackingContext = [
      store.getters.complianceContext,
      store.getters.globalContext,
      store.getters.userContext,
      store.getters.sourceContext,
    ];

    const reactionTrackingData = {
      schema: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_reaction_tag/jsonschema/1-0-0',
      data: payload.data,
    };

    this.trackSelfDescribingEvent(reactionTrackingData, reactionTrackingContext);
  },

  enableDialogTracking(payload) {
    const dialogTrackingContexts = [
      store.getters.complianceContext,
      store.getters.globalContext,
      store.getters.userContext,
      store.getters.sourceContext,
    ];

    const dialogTrackingData = {
      schema: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_dialog_tag/jsonschema/1-0-0',
      data: payload.data,
    };

    this.trackSelfDescribingEvent(dialogTrackingData, dialogTrackingContexts);
  },

  enableFormTracking: throttle(() => {
    const tracker = new Tracking(store);
    tracker.enableFormTracking(store);
  }, 100),

  refreshLinkClickTracking: throttle(() => {
    const tracker = new Tracking(store);
    tracker.refreshLinkTracking();
  }, 100),

  trackSiteSearch(payload) {
    const tracker = new Tracking(store);
    const searchTerms = payload.search_terms;
    const { filter, totalResults, firstPageResults } = payload;

    tracker.trackSiteSearch(
      searchTerms,
      filter,
      totalResults,
      firstPageResults,
    );
  },

  trackPageView() {
    setReferrer();

    const tracker = new Tracking(store);
    tracker.trackPageView(null);
  },
});

const Snowplow = {
  install(Vue, { store }) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$snowplow = api(store);
    // Set up snowplow tracker
    initUserTracking(store);
  },
};

export default Snowplow;
