<template>
  <dialog-base
    class="dialog-base--auth"
    :class="`dialog-base--${kebabCaseAuthType}`"
    :max-width="668"
    show-logo
    :dialog-type="authForm"
    @close="close"
  >
    <template v-slot:heading>
      <template v-if="heading">
        {{ heading }}
      </template>
    </template>
    <template v-slot:copy>
      <template v-if="copy">
        {{ copy }}
      </template>
    </template>
    <div class="auth-dialog">
      <component :is="bodyComponent" />
    </div>
    <progress-indicator v-if="authForm === 'verifyViaEmail' && !siteHasFeature('social_network')" current-step="3" />
    <help-copy-section :dialog-type="authForm" />
    <button
      v-if="!isUGC && authForm === 'verificationOptions'"
      class="text-button text-button--link-style text-button--center"
      @click="handleVerificationSkip"
    >
      Skip this step
    </button>
    <component
      :is="disclaimerComponent"
      class="disclaimer-text--auth"
      data-test-id="auth-disclaimer-text"
    />
  </dialog-base>
</template>

<script>
import DialogBase from '@/components/Global/DialogBase.vue';
import LoginOptions from '@/components/Auth/LoginOptions.vue';
import LoginWithEmail from '@/components/Auth/LoginWithEmail.vue';
import RegisterOptions from '@/components/Auth/RegisterOptions.vue';
import RegisterWithEmail from '@/components/Auth/RegisterWithEmail.vue';
import HelpCopySection from '@/components/Auth/HelpCopySection.vue';
import SetUsername from '@/components/Auth/SetUsername.vue';
import VerificationOptions from '@/components/Auth/VerificationOptions.vue';
import RequestVerificationText from '@/components/Auth/RequestVerificationText.vue';
import EnterVerificationNumber from '@/components/Auth/EnterVerificationNumber.vue';
import DisclaimerComponentWithRecaptcha from '@/components/Auth/DisclaimerComponentWithRecaptcha.vue';
import PhoneDisclaimer from '@/components/Auth/PhoneDisclaimer.vue';
import EmailDisclaimer from '@/components/Auth/EmailDisclaimer.vue';
import PrivacyPolicyAndTermsOfUseLinks from '@/components/Auth/PrivacyPolicyAndTermsOfUseLinks.vue';
import AccountConfirmation from '@/components/Auth/AccountConfirmation.vue';
import ResetPassword from '@/components/Auth/ResetPassword.vue';
import RequestPasswordReset from '@/components/Auth/RequestPasswordReset.vue';
import ProgressIndicator from '@/components/Auth/ProgressIndicator.vue';
import RequestDisorderDemographicInfo from '@/components/Auth/RequestDisorderDemographicInfo.vue';
import FNameAndLName from '@/components/Auth/FNameAndLName.vue';
import SetSocialSites from '@/components/Auth/SetSocialSites.vue';
import { mapGetters } from 'vuex';
import kebabCase from '@/utils/kebab-case';

export default {
  name: 'AuthDialog',
  components: {
    DialogBase,
    LoginOptions,
    LoginWithEmail,
    HelpCopySection,
    RegisterOptions,
    SetUsername,
    RegisterWithEmail,
    DisclaimerComponentWithRecaptcha,
    VerificationOptions,
    RequestVerificationText,
    PhoneDisclaimer,
    EmailDisclaimer,
    PrivacyPolicyAndTermsOfUseLinks,
    EnterVerificationNumber,
    AccountConfirmation,
    ResetPassword,
    RequestPasswordReset,
    ProgressIndicator,
    RequestDisorderDemographicInfo,
    FNameAndLName,
    SetSocialSites,
  },
  computed: {
    ...mapGetters([
      'authForm',
      'siteHasFeature',
      'isUGC',
      'firstTimeLoggingIntoSHN',
      'loggedInFromSocial',
      'authDialogRedirect',
    ]),
    usersEmail() {
      return this.$store.state.currentUser.user.email || 'your email address.';
    },
    setUsernameHeading() {
      return this.firstTimeLoggingIntoSHN ? 'Confirm your username' : 'Choose your username';
    },
    heading() {
      const defaultDialogHeadingForDialogType = {
        logIn: 'Welcome back!',
        register: 'Join in',
        loginWithEmail: 'Log in with email',
        setUsername: this.setUsernameHeading,
        registerWithEmail: 'Sign up with email',
        verificationOptions: 'You\'re almost done!',
        requestVerificationText: 'Verify via text message',
        enterVerificationNumber: 'Enter verification code',
        accountConfirmation: 'You\'re all set!',
        accountConfirmationWithNoCTA: 'You\'re all set!',
        verifyViaEmail: 'Check your inbox',
        resetPassword: 'Create a new password',
        requestPasswordReset: 'Trouble signing in?',
        requestDisorderDemographicInfo: 'Tell us a little about yourself',
        fNameAndLName: this.loggedInFromSocial ? 'Create your account' : 'Please provide your first and last name',
        setSocialSites: 'Where can we find you?',
      }[this.authForm] || '';

      return this.$store.state.authDialogHeading || defaultDialogHeadingForDialogType;
    },
    copy() {
      const defaultDialogTextForDialogType = {
        logIn: 'Log in now - connect with others, stay up on the latest news, or join the conversation.',
        register: 'Ask questions. Stay up on the latest news. And so much more. Create your free account now.',
        loginWithEmail: 'Enter the email address and password associated with your account.',
        verificationOptions: 'Please verify your account.',
        requestVerificationText: 'Enter your phone number and  we\'ll send you a text with your verification code.',
        accountConfirmation: 'Welcome to the community.  We\'re glad to have you here.',
        verifyViaEmail: `We sent an email containing a verification link to ${this.usersEmail}.`,
        requestPasswordReset: 'Enter your username or email address.  If an account exists, we\'ll email you a link to create a new password.',
        accountConfirmationWithNoCTA: 'We\'re so excited you\'re here.',
      }[this.authForm] || '';

      return this.$store.state.authDialogText || defaultDialogTextForDialogType;
    },
    bodyComponent() {
      return {
        logIn: 'login-options',
        register: 'register-options',
        loginWithEmail: 'login-with-email',
        setUsername: 'set-username',
        registerWithEmail: 'register-with-email',
        verificationOptions: 'verification-options',
        requestVerificationText: 'request-verification-text',
        enterVerificationNumber: 'enter-verification-number',
        accountConfirmation: 'account-confirmation',
        resetPassword: 'reset-password',
        requestPasswordReset: 'request-password-reset',
        requestDisorderDemographicInfo: 'request-disorder-demographic-info',
        fNameAndLName: 'f-name-and-l-name',
        setSocialSites: 'set-social-sites',
      }[this.authForm] || '';
    },
    disclaimerComponent() {
      return {
        registerWithEmail: 'disclaimer-component-with-recaptcha',
        loginWithEmail: 'disclaimer-component-with-recaptcha',
        requestPasswordReset: 'disclaimer-component-with-recaptcha',
        requestVerificationText: 'phone-disclaimer',
        register: 'email-disclaimer',
        logIn: 'email-disclaimer',
        setUsername: 'privacy-policy-and-terms-of-use-links',
        verificationOptions: 'privacy-policy-and-terms-of-use-links',
        verifyViaEmail: 'privacy-policy-and-terms-of-use-links',
        accountConfirmation: 'privacy-policy-and-terms-of-use-links',
        accountConfirmationWithNoCTA: 'privacy-policy-and-terms-of-use-links',
        requestDisorderDemographicInfo: 'privacy-policy-and-terms-of-use-links',
        enterVerificationNumber: 'privacy-policy-and-terms-of-use-links',
        fNameAndLName: this.loggedInFromSocial ? 'email-disclaimer' : '',
        setSocialSites: 'privacy-policy-and-terms-of-use-links',
      }[this.authForm] || '';
    },
    kebabCaseAuthType() {
      return kebabCase(this.authForm) || '';
    },
  },
  mounted() {
    this.$store.dispatch('setAuthDialogWasShown');
  },
  beforeDestroy() {
    this.$store.dispatch('clearLogInForm');
  },
  methods: {
    close() {
      if (this.siteHasFeature('social_network') && this.authForm === 'verificationOptions') {
        const redirect = this.$route?.query?.redirect || this.authDialogRedirect;
        const redirectToInfluitive = /^\/?api\/dashboard-redirect/.test(redirect);

        if (redirectToInfluitive) window.location = redirect;
      }

      this.$store.dispatch('closeAuthDialog');
      this.$store.dispatch('clearAuthDialogRedirect');
    },
    handleVerificationSkip() {
      const redirect = this.$route?.query?.redirect || this.authDialogRedirect;
      const redirectToInfluitive = /^\/?api\/dashboard-redirect/.test(redirect);

      if (redirectToInfluitive) {
        window.location = redirect;
        this.$store.dispatch('closeAuthDialog');
        return;
      }

      this.$store.dispatch('setAuthStep', { authStep: 'accountConfirmation' });
    },
  },
};
</script>

<docs>
Renders a dialog for users to login or create an account

</docs>
