<template>
  <button
    type="button"
    class="logout-button"
    @click.prevent="handleLogoutClick"
  >
    <icon-component name="exit" class="logout-button__icon" />
    Log out
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LogoutButton',
  computed: {
    ...mapGetters(['siteHasFeature']),
    isProtectedRoute() {
      return this.$route.matched.some((routeMatch) => {
        if (!routeMatch.meta) { return false; }
        return routeMatch.meta.requiresAuth || routeMatch.meta.notLoggedIn;
      });
    },
  },
  methods: {
    handleLogoutClick(event) {
      this.$snowplow.trackButtonEvent({
        data: {
          type: 'log out',
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
      this.redirectFromProtectedRoutes().then(() => {
        this.logOut();
      });
    },
    redirectFromProtectedRoutes() {
      return new Promise((resolve) => {
        if (this.isProtectedRoute) {
          this.$router.push(this.$route.query.redirect || { name: 'home' });
        }
        resolve();
      });
    },
    logOut() {
      this.$auth.logOut().then((response) => {
        this.$apolloProvider.defaultClient.cache.reset();
        this.$apolloProvider.phoenixSocket.disconnect();
        this.$store.dispatch('userLogOut');

        if (
          this.siteHasFeature('social_network')
          && response.redirect
        ) { window.location = response.redirect; }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_logout-button';
</style>

<docs>
</docs>
