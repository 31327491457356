<template>
  <div class="layout layout--fullbleed">
    <div v-if="showLeaderboardAd" class="layout__leaderboard-container">
      <ad-slot mapping="leaderboard" />
    </div>
    <slot name="hero" />
    <div id="layout-content" class="layout__content">
      <not-found v-if="is404" />
      <slot v-else />
    </div>
  </div>
</template>

<script>
import AdSlot from '@/components/Ads/AdSlot.vue';
import NotFound from '@/components/Global/NotFound.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'FullBleedLayout',
  components: {
    AdSlot,
    NotFound,
  },
  props: {
    showLeaderboardAd: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['is404']),
  },
};
</script>
