<template>
  <li
    :class="`reaction-option ${isSelected ? 'reaction-option--selected' : ''}`"
  >
    <button
      :value="`${id}--${type}`"
      :class="`reaction-option__button reaction-option__button--${type}`"
      @click="handleReaction"
    >
      <icon-component :name="type" class="reaction-option__icon" />
      <span class="visually-hidden">React with</span>
      {{ formatType }}
      <span v-if="count > 0" class="reaction-option__count" data-test-id="reaction-option-count">
        {{ count }}
      </span>
    </button>
  </li>
</template>

<script>
export default {
  name: 'ReactionOption',
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      default: 0,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formatType() {
      return this.type.charAt(0).toUpperCase() + this.type.slice(1);
    },
  },
  methods: {
    handleReaction(event) {
      this.$snowplow.trackButtonEvent({
        data: {
          type: 'reaction',
          text: event.currentTarget.innerText,
          classes: event.target.classList,
          id: event.target.id,
        },
      });
      if (this.isSelected) {
        this.$emit('remove-reaction');
        return;
      }
      this.$emit('select-reaction');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_reaction-option';
</style>

<style lang="scss"> // no scoped
  // because these styles require reaching classes of child components this must
  // be in a non-scoped style tag. There is a "deep" combinator available in vue
  // (::v-deep, /deep/, or >>>)
  // but it is not compatible with our usage of sass-loader

  // Styles for each reaction type on hover and selected
  // This must come last to ensure the cascade works properly.
  @for $i from 1 through length($reactions) {
    .reaction-option__button--#{nth($reactions, $i)} {
      @include landscape-tablet-up {
        &:hover {
          svg {
            fill: nth($reactions-outline-colors, $i);
          }
          .reaction-icon-background {
            fill: nth($reactions-bg-colors, $i);
          }
          .reaction-option__count {
            color: nth($reactions-outline-colors, $i);
          }
        }
      }

      .reaction-option--selected & {
        svg {
          fill: nth($reactions-outline-colors, $i);
        }

        .reaction-icon-background {
          fill: nth($reactions-bg-colors, $i);
        }

        .reaction-option__count {
          @include a11y-contrast-changes(color);
          color: nth($reactions-outline-colors, $i);
        }
      }

      .reaction-grid & {
        fill: nth($reactions-outline-colors, $i);

        .reaction-option__count {
          @include a11y-contrast-changes(color);
          color: nth($reactions-outline-colors, $i);
        }
      }
    }
  }
</style>

<docs>
A Singular Reaction that gets called from a ReactionMenu
or ReactionGrid.  It takes an id, type, a count and isSelected
as props. It displays an SVG, text and a number.
Based on where it is called, it will look very different.

</docs>
